/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Button, TextField, Card as MUICard, Box, Stack, Badge } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Swal from "sweetalert2";
import OicLeadIcon from "layouts/crm_dashboard/icons/OicLeadIcon";
import Star from "./iconComponents/Star";
import DateTimeFormatter from "./DateTimeFormatter";

const useStyles = makeStyles(() => ({
  kanbanColumn: {
    backgroundColor: "white",
    margin: "1%",
    borderRadius: "5px",
    borderRight: "1px solid #5F5F5F26",
    paddingRight: "3%",
    paddingLeft: "0.5%",
    minWidth: "260px",
    width: "100%",
    minHeight: "260px",
  },
  card: {
    width: "230px",
    borderRadius: "5px",
    marginTop: "6%",
    padding: "3% 5%",
    fontSize: "14px",
    boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    position: "relative",
  },
  status: { fontSize: "14px", fontWeight: "700", fontFamily: "Poppins" },
  filteredData: { fontSize: "14px", fontWeight: "600", fontFamily: "Poppins" },
  itemStatus: {
    borderRadius: "5px",
    padding: "2% 7%",
    fontSize: "10px",
    cursor: "pointer",
    textAlign: "center",
    fontWeight: "700",
    marginBottom: "3%",
    fontFamily: "Poppins",
  },
  lead: { fontSize: "14px", fontWeight: "500", fontFamily: "Poppins" },
  appointment: {
    fontSize: "10px",
    fontWeight: "500",
    marginBottom: "4%",
    fontFamily: "Poppins",
  },
}));

function getStatusBorderColor(status) {
  switch (status) {
    case "Favorites":
      return "#9de7c3";
    case "Follow Up":
      return "#6ef0b0";
    case "Offers":
      return "#53cc91";
    case "Deals":
      return "#0e9553";
    default:
      return "#92CAFF";
  }
}

function getHoverStatusBorderColor(status) {
  switch (status) {
    case "Favorites":
      return "#6ef0b0";
    case "Follow Up":
      return "#53cc91";
    case "Offers":
      return "#0e9553";
    case "Deals":
      return "#00572d";
    default:
      return "";
  }
}

function getColor(status) {
  switch (status) {
    case "Favorites":
      return "#9de7c3";
    case "Follow Up":
      return "#6ef0b0";
    case "Offers":
      return "#53cc91";
    case "Deals":
      return "#0e9553";
    default:
      return "#75BDFF";
  }
}

function OicKanbanData({
  data,
  campaignId,
  openAppointment,
  handleFavoriteClick,
  isFavourite,
  customerUser,
  fetchCampaignAppointmentsOrOics,
  oicSearch,
}) {
  const classes = useStyles();
  const [newCardForm, setNewCardForm] = useState(false);
  const [formValue, setFormValue] = useState("");
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDrop = (status) => {
    let review_status = "";
    switch (status) {
      case "Follow Up":
        review_status = "follow_up";
        break;
      case "Offers":
        review_status = "offer";
        break;
      case "Deals":
        review_status = "deal";
        break;
      default:
        review_status = "";
        break;
    }

    const payload = {
      review_status,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${draggedItem.campaign_lead_id}/review-status`,
        payload
      )
      .then(() => {
        if (campaignId === null) {
          fetchCampaignAppointmentsOrOics(0);
        } else {
          fetchCampaignAppointmentsOrOics(campaignId);
        }
        setDraggedItem(null);
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  const handleCardClick = (id, className) => {
    openAppointment({ id, class_name: className });
  };

  return (
    <Box mt={2}>
      <Box mt={2} mb={4} height="500px" className={classes.container} overflow="auto">
        {["Favorites", "Follow Up", "Offers", "Deals"].map((status) => {
          const filteredData = data.filter((item) => {
            const searchedData = item.lead_identificator
              .toLowerCase()
              .includes(oicSearch.toLowerCase());

            if (isFavourite === 1 && item.is_favorite !== 1) {
              return false;
            }

            if (
              status === "Favorites" &&
              (item.customer_review_status === null || item.customer_review_status === "favorite")
            ) {
              return item.class_name === "success" && searchedData;
            }
            if (status === "Follow Up") {
              return item.customer_review_status === "follow_up" && searchedData;
            }
            if (status === "Offers") {
              return item.customer_review_status === "offer" && searchedData;
            }
            if (status === "Deals") {
              return item.customer_review_status === "deal" && searchedData;
            }
            return false;
          });
          const statusColor = getColor(status);

          return (
            <Box
              key={status}
              className={`${classes.kanbanColumn} ${
                draggedItem?.status === status ? classes.validDrop : ""
              }`}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.target.classList.add(classes.validDrop)}
              onDragLeave={(e) => e.target.classList.remove(classes.validDrop)}
              onDrop={() => handleDrop(status)}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap={0.5}
                display="flex"
                justifyContent="space-between"
              >
                <Typography className={classes.status}>{status}</Typography>
                <Badge
                  badgeContent={filteredData.length}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: statusColor,
                      color: "white",
                      zIndex: 0,
                      "&:hover": {
                        backgroundColor: `${getHoverStatusBorderColor(status)}`,
                      },
                    },
                  }}
                >
                  <Typography className={classes.filteredData} />
                </Badge>
              </Stack>
              {filteredData.map((item) => (
                <MUICard
                  key={item.id}
                  className={classes.card}
                  sx={{
                    border: `1px solid ${getStatusBorderColor(status)}`,
                    opacity: draggedItem?.id === item.id ? 0.5 : 1,
                    cursor: "pointer",
                    "&:hover": {
                      border: `1px solid ${getHoverStatusBorderColor(status)}`,
                    },
                  }}
                  draggable
                  onDragStart={() => setDraggedItem(item)}
                  onDragEnd={() => setDraggedItem(item)}
                  onDoubleClick={() => handleCardClick(item.id, item.class_name)}
                >
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography className={classes.lead}>
                      {item.lead_identificator.split(" /")[0].replace("Firmenname: ", "")}
                    </Typography>
                    {/* <LinearProgress
                      onClick={() => handleCardClick(item.id, item.class_name)}
                      sx={{
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: getStatusBorderColor(item.status),
                        },
                        width: item.client_rating,
                      }}
                      variant="determinate"
                      value={item.client_rating}
                    /> */}
                  </Stack>
                  <Typography fontSize="13px" color="#959595">
                    Campaign: {item.campaign_name}
                  </Typography>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.appointment}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <OicLeadIcon width={15} height={15} color="#5F5F5F" />
                        <DateTimeFormatter dateTime={item.created_at} />
                      </div>
                    </Typography>
                    <Typography
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        handleFavoriteClick(item);
                      }}
                    >
                      <Star fill={item.is_favorite === 1 ? "#EBCB24" : "transparent"} />
                    </Typography>
                  </Stack>
                </MUICard>
              ))}
            </Box>
          );
        })}
        {/* New card form */}
        {newCardForm && (
          <Box className={classes.kanbanColumn}>
            <Box textAlign="center">
              <Typography variant="h6">{newCardForm}</Typography>
            </Box>
            <Box my={2.5}>
              <TextField
                value={formValue}
                multiline
                rows={2}
                onChange={(e) => setFormValue(e.target.value)}
              />
              <Box display="flex" mt={2}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={() => {
                    setFormValue("");
                  }}
                >
                  Add
                </Button>
                <Box ml={1}>
                  <Button
                    variant="contained"
                    color="light"
                    size="small"
                    onClick={() => setNewCardForm(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

OicKanbanData.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      className: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          template: PropTypes.node.isRequired,
          className: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  openAppointment: PropTypes.func.isRequired,
  campaignId: PropTypes.any.isRequired,
  isFavourite: PropTypes.number.isRequired,
  handleFavoriteClick: PropTypes.func.isRequired,
  customerUser: PropTypes.any.isRequired,
  fetchCampaignAppointmentsOrOics: PropTypes.any.isRequired,
  oicSearch: PropTypes.any.isRequired,
};

export default OicKanbanData;
