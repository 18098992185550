import axios from "axios";

// Search for people
export const searchPeople = async (
  customerId,
  query,
  start = 1,
  pageSize = 10,
  orderBy = "relevance"
) => {
  try {
    const response = await axios.post(`/api/customers/${customerId}/prospect360/people/search`, {
      query,
      start,
      page_size: pageSize,
      order_by: orderBy.toLocaleLowerCase(),
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || `API request failed: ${error.message}`);
  }
};

// Search for companies
export const searchCompanies = async (
  customerId,
  query,
  start = 1,
  pageSize = 10,
  orderBy = "relevance"
) => {
  try {
    const response = await axios.post(`/api/customers/${customerId}/prospect360/companies/search`, {
      query,
      start: start === 1 ? start : start * pageSize + 1 - pageSize,
      page_size: pageSize,
      order_by: orderBy.toLocaleLowerCase(),
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || `API request failed: ${error.message}`);
  }
};

// Lookup person details
export const lookupPerson = async (customerId, params) => {
  try {
    const response = await axios.get(`/api/customers/${customerId}/prospect360/people/lookup`, {
      params,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || `API request failed: ${error.message}`);
  }
};

// Lookup company details
export const lookupCompany = async (customerId, params) => {
  try {
    const response = await axios.get(`/api/customers/${customerId}/prospect360/companies/lookup`, {
      params,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || `API request failed: ${error.message}`);
  }
};

// Check status of a person lookup
export const checkPersonStatus = async (customerId, ids) => {
  try {
    let params = {};
    if (Array.isArray(ids)) {
      // Axios handles repeated parameters automatically
      params = { ids };
    } else {
      params = { ids: [ids] };
    }

    const response = await axios.get(`/api/customers/${customerId}/prospect360/people/status`, {
      params,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || `API request failed: ${error.message}`);
  }
};

export default axios;
