import React from "react";
import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import PropTypes from "prop-types";

const keyMapping = {
  "ort-1574245453": "City",
  "zip-1574245433": "Zip",
  "name-1574245387": "Name",
  xing_1591785522: "Xing",
  cloud_1697440994: "Cloud",
  "email-1574245510": "Email",
  "mobil-1574245529": "Mobile",
  backup_1583250034: "Backup",
  domain_1577994166: "Domain",
  extern_1601285688: "Extern",
  sektor_1591787312: "Sector",
  branche_1591787194: "Industry",
  "strasse-1574245420": "Street",
  "telefon-1574245499": "Phone",
  "vorname-1574245408": "First Name",
  vorwahl_1617024544: "Area Code",
  "anrede-1_1583250907": "Salutation",
  funktion_1577992162: "Function",
  linkedin_1591785546: "LinkedIn",
  durchwahl_1583250907: "Extension",
  kategorie_1634113690: "Category",
  "bundesland-1574245479": "State",
  hersteller_1583847529: "Manufacturer",
  "erp-systeme_1617006206": "ERP Systems",
  mitarbeiter_1626097457: "Employees",
  clientanzahl_1669891184: "Clients",
  "company-name_1577967963": "Company Name",
  beschaeftigte_1591787289: "Employed",
  "tb-im-einsatz_1583249990": "TB in Use",
  ansprechpartner_1589199086: "Contact Person",
  "letzter-kontakt_1624447809": "Last Contact",
  virtualisierung_1583249611: "Virtualization",
  "social-network-weitere-networks_1601285770": "Other Social Networks",
};

const LeadInfoTable = ({ leadInfo }) => (
  <Table size="small">
    <TableBody>
      {Object.entries(leadInfo).map(([key, value]) => {
        const label = keyMapping[key] || key;
        return (
          <TableRow key={key}>
            <TableCell sx={{ fontWeight: "bold" }}>{label}</TableCell>
            <TableCell>{value && value.trim() ? value : "-"}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);

LeadInfoTable.propTypes = {
  leadInfo: PropTypes.object.isRequired,
};

export default LeadInfoTable;
