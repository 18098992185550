/* eslint-disable camelcase */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  TextField,
  Card as MUICard,
  Box,
  Stack,
  Grid,
  Badge,
} from "@mui/material";
import DateTimeFormatter from "./DateTimeFormatter";
import Star from "./iconComponents/Star";

const useStyles = makeStyles(() => ({
  kanbanData: {
    height: "60vh",
    overflow: "auto",
    marginTop: "10px",
  },
  "@media (min-width: 1120px)": {
    kanbanData: {
      height: "80vh",
    },
  },
  kanbanColumn: {
    backgroundColor: "white",
    margin: "1%",
    borderRadius: "5px",
    borderRight: "1px solid #5F5F5F26",
    paddingRight: "3%",
    paddingLeft: "0.5%",
    minWidth: "260px",
    width: "100%",
    minHeight: "260px",
  },
  card: {
    width: "230px",
    borderRadius: "5px",
    marginTop: "6%",
    padding: "3% 5%",
    fontSize: "14px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    position: "relative",
  },
  status: { fontSize: "14px", fontWeight: "700", fontFamily: "Poppins" },
  filteredData: { fontSize: "14px", fontWeight: "600", fontFamily: "Poppins" },
  itemStatus: {
    borderRadius: "5px",
    padding: "2% 7%",
    fontSize: "10px",
    cursor: "pointer",
    textAlign: "center",
    fontWeight: "700",
    marginBottom: "3%",
    fontFamily: "Poppins",
  },
  lead: { fontSize: "14px", fontWeight: "500", fontFamily: "Poppins" },
  appointment: {
    fontSize: "10px",
    fontWeight: "500",
    marginBottom: "4%",
    fontFamily: "Poppins",
  },
}));

function getStatusBorderColor(status) {
  switch (status) {
    case "New Appointment":
      return "#75BDFF";
    case "Success":
      return "#9de7c3";
    case "Approved":
      return "#FF8B22";
    case "In progress":
      return "#FF8383";
    case "Follow Up":
      return "#6ef0b0";
    case "Offers":
      return "#53cc91";
    case "Deals":
      return "#0e9553";
    default:
      return "";
  }
}

function getHoverStatusBorderColor(status) {
  switch (status) {
    case "New Appointment":
      return "#0085ff";
    case "Success":
      return "#00fb81";
    case "Approved":
      return "#fb4c00";
    default:
      return "";
  }
}

// function getStatusBackgroundColor(status) {
//   switch (status) {
//     case "New Appointment":
//       return "#dceeff";
//     case "Success":
//       return "#0cc46b14";
//     case "Approved":
//       return "#FF8B2214";
//     case "In progress":
//       return "#FF838314";
//     case "Follow Up":
//       return "#eefdf3";
//     case "Offers":
//       return "#e9fff0";
//     case "Deals":
//       return "#e0ffe9";
//     default:
//       return "";
//   }
// }

function getColor(status) {
  switch (status) {
    case "Success":
      return "#9de7c3";
    case "Approved":
      return "#FF9455";
    case "In progress":
      return "#FF8383";
    case "Deals":
      return "#0e9553";
    case "New Appointment":
      return "#75BDFF";
    case "Follow Up":
      return "#6ef0b0";
    case "Offers":
      return "#53cc91";
    default:
      return "";
  }
}

// function getText(status) {
//   switch (status) {
//     case "Not Confirmed":
//       return "New";
//     case "Success":
//       return "Success";
//     case "Approved":
//       return "Approved";
//     case "In progress":
//       return "In Progress";
//     case "Follow Up":
//       return "Follow Up";
//     case "Deals":
//       return "Deals";
//     case "Offers":
//       return "Offers";
//     case "New Appointment":
//       return "New Appointment";
//     default:
//       return "";
//   }
// }

const AppointmentKanban = ({ data, openAppointment, handleFavoriteClick, isFavourite, search }) => {
  const classes = useStyles();
  const [newCardForm, setNewCardForm] = useState(false);
  const [formValue, setFormValue] = useState("");
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDrop = (status) => {
    if (draggedItem) {
      data.map((item) => (item.id === draggedItem.id ? { ...item, class_name: status } : item));

      setDraggedItem(null);
    }
  };
  // const [search, setSearch] = useState("");

  // const onSearchChange = (e) => {
  //   setSearch(e.target.value);
  // };

  const handleCardClick = (id, className) => {
    openAppointment({ id, class_name: className });
  };

  // const options =
  //   campaignData?.map((campaign) => ({
  //     value: campaign.id,
  //     label: campaign.name,
  //   })) || [];

  // const displayValue = campaignData
  //   .filter((campaign) => campaignValue.includes(campaign.id))
  //   .map((campaign) => ({
  //     value: campaign.id,
  //     label: campaign.name,
  //   }));

  // const handleCampaignValueChange = (e) => {
  //   if (e.some((item) => item.label === "All")) {
  //     setCampaignValue(campaignData.map((item) => item.id).concat([0]));
  //   } else if (e.length === 0) {
  //     setCampaignValue([]);
  //   } else {
  //     setCampaignValue(e.map((item) => item.value));
  //   }
  // };

  // const statusOptions = [
  //   { value: "info", label: `New appointments(${reviewStatusVal.info})` },
  //   { value: "warning", label: `Approved(${reviewStatusVal.warning})` },
  //   { value: "success", label: `Successful(${reviewStatusVal.success})` },
  // ];

  // const statusDisplayValue = statusOptions
  //   .filter((status) => reviewStatus.includes(status.value))
  //   .map((status) => ({
  //     value: status.value,
  //     label: status.label,
  //   }));

  // const handleStatusChange = (selectedOptions) => {
  //   if (selectedOptions.some((item) => item.label === "All")) {
  //     setReviewStatus(statusOptions.map((item) => item.value).concat([0])); // Use item.value
  //   } else if (selectedOptions.length === 0) {
  //     setReviewStatus([]);
  //   } else {
  //     setReviewStatus(selectedOptions.map((item) => item.value)); // Use item.value
  //   }
  // };

  return (
    <Box p={{ xs: 0, lg: 1.5, sm: 0 }}>
      <Grid
        container
        // justifyContent="space-between"
        alignItems="center"
        gap={2}
        direction={{ xs: "column", md: "row" }}
      >
        {/* <Typography fontSize="20px" fontWeight="600" color="#010101" mb={2} ml={1.5}>
          Scheduled Appointments
        </Typography> */}
        {/* <Stack
          direction="row"
          sx={{
            backgroundColor: "#F7F7F7",
            borderRadius: "5px",
            paddingTop: "1%",
            paddingBottom: "1%",
            paddingLeft: "1%",
          }}
          alignItems="center"
        >
          <Icon sx={{ color: "#FF4D00D9" }}>search</Icon>
          <input
            style={{
              border: "0",
              background: "transparent",
              outline: "none",
            }}
            type="text"
            value={search}
            placeholder="Search Appointment"
            onChange={onSearchChange}
          />
        </Stack> */}
        {/* <div className={classes.filters}>
          <MultiSelect
            key={campaignValue.length}
            className={classes.datePicker}
            placeholder="Campaign"
            isMulti
            options={options}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={displayValue}
            onChange={handleCampaignValueChange}
            allowSelectAll={false}
          />
        </div>

        <div className={classes.filters}>
          <MultiSelect
            className={classes.datePicker}
            placeholder="Status"
            isMulti
            options={statusOptions}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={statusDisplayValue}
            onChange={handleStatusChange}
            allowSelectAll={false}
          />
        </div> */}
      </Grid>
      <div className={classes.kanbanData}>
        <Box mb={4} className={classes.container}>
          {["New Appointment", "Approved", "Success"].map((status) => {
            const filteredData = data?.filter((item) => {
              const searchedData = item.lead_identificator
                .toLowerCase()
                .includes(search.toLowerCase());
              if (isFavourite === 1 && item.is_favorite !== true) {
                return false;
              }
              if (status === "New Appointment") {
                return item.class_name === "info" && searchedData;
              }
              if (status === "Approved") {
                return item.class_name === "warning" && searchedData;
              }
              if (status === "Success") {
                return item.class_name === "success" && searchedData;
              }

              return searchedData;
            });

            const statusColor = getColor(status);

            return (
              <Box
                key={status}
                className={`${classes.kanbanColumn} ${
                  draggedItem?.status === status ? classes.validDrop : ""
                }`}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                onDragEnter={(e) => {
                  e.preventDefault();
                  e.target.classList.add(classes.validDrop);
                }}
                onDragLeave={(e) => {
                  e.preventDefault();
                  e.target.classList.remove(classes.validDrop);
                }}
                onDrop={() => {
                  handleDrop(status);
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={0.5}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography className={classes.status}>{status}</Typography>
                  <Badge
                    badgeContent={filteredData?.length}
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: statusColor,
                        color: "#fff",
                        zIndex: 0,
                        height: 20,
                        "&:hover": {
                          backgroundColor: `${getHoverStatusBorderColor(status)}`,
                        },
                      },
                    }}
                  >
                    <Typography className={classes.filteredData} />
                  </Badge>
                </Stack>
                {filteredData?.map((item) => (
                  <MUICard
                    key={item.id}
                    className={classes.card}
                    sx={{
                      border: `1px solid ${getStatusBorderColor(status)}`,
                      opacity: draggedItem?.id === item.id ? 0.5 : 1,
                      cursor: "pointer",
                      "&:hover": {
                        border: `1px solid ${getHoverStatusBorderColor(status)}`,
                      },
                    }}
                    draggable
                    onDragStart={() => setDraggedItem(item)}
                    onDragEnd={() => setDraggedItem(item)}
                    onDoubleClick={() => handleCardClick(item.id, item.class_name)}
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography className={classes.lead}>
                        {item.lead_identificator.replace("Firmenname: ", "")}
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography className={classes.appointment}>
                        <DateTimeFormatter dateTime={new Date(item.appointment_at)} />
                      </Typography>
                      <Typography
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          handleFavoriteClick(item);
                        }}
                      >
                        <Star fill={item.is_favorite === true ? "#EBCB24" : "transparent"} />
                      </Typography>
                    </Stack>
                  </MUICard>
                ))}
              </Box>
            );
          })}
          {/* New card form */}
          {newCardForm && (
            <Box className={classes.kanbanColumn}>
              <Box textAlign="center">
                <Typography variant="h6">{newCardForm}</Typography>
              </Box>
              <Box my={2.5}>
                <TextField
                  value={formValue}
                  multiline
                  rows={2}
                  onChange={(e) => setFormValue(e.target.value)}
                />
                <Box display="flex" mt={2}>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => {
                      // Add new card logic
                      setFormValue("");
                    }}
                  >
                    Add
                  </Button>
                  <Box ml={1}>
                    <Button
                      variant="contained"
                      color="light"
                      size="small"
                      onClick={() => setNewCardForm(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </div>
    </Box>
  );
};

// Add prop type validations
AppointmentKanban.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.any.isRequired,
      className: PropTypes.any.isRequired,
      info: PropTypes.string.isRequired,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          template: PropTypes.node.isRequired,
          className: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  openAppointment: PropTypes.func.isRequired,
  handleFavoriteClick: PropTypes.func.isRequired,
  isFavourite: PropTypes.number.isRequired,
  search: PropTypes.any.isRequired,
};

export default AppointmentKanban;
