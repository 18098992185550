import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography, Divider, Button, Grid } from "@mui/material";
import LeadInfoTable from "./LeadInfoTable";

const CampaignCard = ({ name, data }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => setExpanded(!expanded);

  const appointmentDate = data.appointment_at
    ? new Date(data.appointment_at).toLocaleString()
    : "-";

  return (
    <Card sx={{ margin: 2, padding: 2 }}>
      <CardContent>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          {name}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              <strong>Company:</strong> {data.lead_info["company-name_1577967963"] || "-"}
            </Typography>
            <Typography variant="body2">
              <strong>Location:</strong> {data.lead_info["ort-1574245453"] || "-"},{" "}
              {data.lead_info["zip-1574245433"] || "-"}
            </Typography>
            <Typography variant="body2">
              <strong>Email:</strong> {data.lead_info["email-1574245510"] || "-"}
            </Typography>
            <Typography variant="body2">
              <strong>Phone:</strong> {data.lead_info["telefon-1574245499"] || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {data.appointment_status && (
              <>
                <Typography variant="subtitle1">
                  <strong>Appointment</strong>
                </Typography>
                <Typography variant="body2">
                  <strong>Date:</strong> {appointmentDate}
                </Typography>
                <Typography variant="body2">
                  <strong>Status:</strong> {data.appointment_status || "-"}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Typography variant="subtitle1">Customer Note:</Typography>
        <div
          dangerouslySetInnerHTML={{
            __html: data.note_for_customer || "-",
          }}
        />
        <Divider sx={{ my: 2 }} />
        <Button variant="contained" onClick={toggleExpand}>
          {expanded ? "Hide Details" : "Show Details"}
        </Button>
        {expanded && (
          <div style={{ marginTop: 20 }}>
            <Typography variant="h6">Lead Information</Typography>
            <LeadInfoTable leadInfo={data.lead_info} />
            {data.internal_customer_note && (
              <>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle1">Internal Customer Note:</Typography>
                <Typography variant="body2">{data.internal_customer_note}</Typography>
              </>
            )}
            {data.follow_status && (
              <>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle1">Follow Status:</Typography>
                <Typography variant="body2">{data.follow_status}</Typography>
              </>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

CampaignCard.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default CampaignCard;
