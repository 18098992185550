import React from "react";
import PropTypes from "prop-types";
import { Box, CardContent, Grid, Typography, Button, Chip, Divider } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Business } from "@mui/icons-material";

const NonEnriched = ({ data }) => (
  <Box sx={{ padding: 0 }}>
    <Grid container spacing={3} sx={{ display: "flex", direction: "column" }}>
      <Grid item xs={12} sm={3}>
        <CardContent>
          {/* Name and Job Title */}
          <Typography variant="b6" fontWeight="bold" gutterBottom>
            {data.current_title} @ {data.current_employer}
          </Typography>

          {/* LinkedIn Button */}
          <Box sx={{ marginTop: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              href={data.linkedin_url}
              target="_blank"
              startIcon={<LinkIcon />}
            >
              LinkedIn
            </Button>
          </Box>

          {/* Location */}
          <Box sx={{ marginTop: 2, display: "flex", alignItems: "center" }}>
            <LocationOnIcon sx={{ marginRight: 1 }} />
            <Typography variant="body2">{data.location}</Typography>
          </Box>
        </CardContent>
      </Grid>

      <Grid item xs={12} sm={1}>
        <Divider orientation="vertical" flexItem sx={{ mx: 2, height: "100%" }} />
      </Grid>

      <Grid item xs={12} sm={3}>
        <Typography variant="body1" color="textSecondary" fontWeight="bold" sx={{ marginTop: 2 }}>
          <Business sx={{ marginRight: 1 }} />
          Professional Emails:
        </Typography>
        {data.teaser.professional_emails.map((email) => (
          <Typography variant="body2" sx={{ marginBottom: 1 }}>
            {email}
          </Typography>
        ))}

        <Typography variant="body1" color="textSecondary" fontWeight="bold">
          <EmailIcon sx={{ marginRight: 1 }} />
          Personal Emails:
        </Typography>
        {data.teaser.personal_emails.map((email) => (
          <Typography variant="body2" sx={{ marginBottom: 1 }}>
            {email}
          </Typography>
        ))}
      </Grid>

      <Grid item xs={12} sm={1}>
        <Divider orientation="vertical" flexItem sx={{ mx: 2, height: "100%" }} />
      </Grid>

      <Grid item xs={12} sm={3}>
        <Typography variant="body1" color="textSecondary" fontWeight="bold">
          <PhoneIcon sx={{ marginRight: 1 }} />
          Phone Numbers:
        </Typography>
        {data.teaser.phones.map((phone) => (
          <Typography
            variant="body2"
            sx={{
              marginBottom: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            {phone.number}{" "}
            {phone.is_premium && (
              <Chip
                label="Premium"
                color="primary"
                size="small"
                sx={{ marginLeft: 1, fontWeight: "bold", textTransform: "capitalize" }}
              />
            )}
          </Typography>
        ))}
      </Grid>
    </Grid>
  </Box>
);

NonEnriched.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default NonEnriched;
