import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Pagination,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import { Search, Close, Add } from "@mui/icons-material";
import UniversalDropdown from "./UniversalDropdown";

function SearchHeader({
  tabValue,
  setTabValue,
  searchQuery,
  setSearchQuery,
  handleSearch,
  loading,
  results,
  setPageSize,
  pageSize,
  setPage,
  page,
  setOrderBy,
  orderBy,
}) {
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  return (
    <Grid item container>
      <Grid
        item
        container
        mb={2}
        lg={12}
        md={12}
        sm={12}
        xs={11}
        sx={{
          backgroundColor: "#FFFFFF",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
        }}
      >
        <Grid item container spacing={2} alignItems="center">
          {/* Tabs */}
          <Grid item>
            <Paper
              elevation={0}
              sx={{
                borderRadius: "24px",
                backgroundColor: "#f5f7fa",
                padding: "4px",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{
                  minHeight: "40px",
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                  "& .MuiTab-root": {
                    minHeight: "40px",
                    borderRadius: "20px",
                    padding: "8px 20px",
                    textTransform: "none",
                    fontWeight: 500,
                    color: "#666",
                    "&.Mui-selected": {
                      color: "rgba(255, 77, 0, 0.85)",
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                    },
                  },
                }}
              >
                <Tab label="People" />
                <Tab label="Companies" />
              </Tabs>
            </Paper>
          </Grid>

          {/* Search Bar */}
          <Grid item xs>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
              }}
            >
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  backgroundColor: "rgba(255, 77, 0, 0.85)",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0 8px",
                }}
              >
                <Typography variant="caption" sx={{ color: "white", fontWeight: "bold" }} />
              </Box>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setPage(1);
                    handleSearch();
                  }
                }}
              />
              {searchQuery && (
                <IconButton size="small" onClick={clearSearch}>
                  <Close fontSize="small" />
                </IconButton>
              )}
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : <SearchIcon />}
              >
                <Search
                  onClick={() => {
                    setPage(1);
                    handleSearch();
                  }}
                />
              </IconButton>
            </Paper>
          </Grid>

          {/* Tag Selector */}
          <Grid item>
            <Paper
              sx={{
                display: "flex",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
              }}
            >
              <UniversalDropdown
                options={["Relevance", "Popularity", "Score"]}
                defaultValue={orderBy}
                label="Sort By:"
                onChange={setOrderBy}
              />
              <UniversalDropdown
                options={[10, 20, 50, 100]}
                defaultValue={10}
                label="Page Size: "
                onChange={setPageSize}
              />
              <Divider orientation="vertical" flexItem />
              <IconButton size="small" sx={{ p: "8px" }}>
                <Add fontSize="small" />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>

        {results && results.pagination && results.pagination.total > 0 && (
          <Grid
            item
            container
            xs={12}
            sx={{ mt: 2 }}
            alignItems="center"
            justifyContent={{ xs: "center", md: "space-between" }}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Grid item>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: { xs: 1, md: 0 } }} // Add margin bottom in small screens
              >
                {page === 1
                  ? `${page} - ${pageSize}`
                  : `${page * pageSize + 1 - pageSize} - ${page * pageSize + pageSize}`}{" "}
                of about {results.pagination.total} results.
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent={{ xs: "center", md: "flex-end" }}
              // width="100%"
            >
              <Pagination
                count={Math.ceil(results.pagination.total / pageSize)}
                page={page}
                onChange={(_, value) => setPage(value)}
                disabled={loading}
                color="primary"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

SearchHeader.propTypes = {
  tabValue: PropTypes.objectOf(PropTypes.object).isRequired,
  setTabValue: PropTypes.objectOf(PropTypes.object).isRequired,
  searchQuery: PropTypes.objectOf(PropTypes.object).isRequired,
  setSearchQuery: PropTypes.objectOf(PropTypes.func).isRequired,
  handleSearch: PropTypes.objectOf(PropTypes.func).isRequired,
  loading: PropTypes.bool.isRequired,
  results: PropTypes.objectOf(PropTypes.object).isRequired,
  setPageSize: PropTypes.objectOf(PropTypes.func).isRequired,
  pageSize: PropTypes.number.isRequired,
  setPage: PropTypes.objectOf(PropTypes.func).isRequired,
  page: PropTypes.number.isRequired,
  setOrderBy: PropTypes.objectOf(PropTypes.func).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default SearchHeader;
