/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/default-props-match-prop-types */
import React from "react";
import PropTypes from "prop-types";
import ReactSelect, { components } from "react-select";
import { Checkbox } from "@mui/material";
import { styled } from "@material-ui/core/styles";
import styles from "components/SuiSelect/styles";
import colors from "assets/theme/base/colors";

const OrangeCheckbox = styled(Checkbox)(() => ({
  color: "#FF7701",
  "&.Mui-checked": {
    color: "#FF7701",
  },
}));

const MultiSelect = (props) => {
  const { light } = colors;

  const allSelected = props.value.length === props.options.length;
  const clearAllOption = { label: "Clear All", value: "clear_all" };

  const handleCheckboxChange = (option) => {
    let newValue;

    if (option.value === props.allOption.value) {
      newValue = props.value.length < props.options.length ? props.options : [];
    } else {
      const index = props.value.findIndex((item) => item.value === option.value);
      newValue =
        index === -1
          ? [...props.value, option]
          : props.value.filter((item) => item.value !== option.value);
    }

    props.onChange(newValue);
  };

  const formatOptions = (options) =>
    options.map((option) => ({
      ...option,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <OrangeCheckbox
            checked={allSelected || props.value.some((item) => item.value === option.value)}
            onChange={() => handleCheckboxChange(option)}
            style={{ marginRight: "10px" }}
          />
          <span>{option.label}</span>
        </div>
      ),
    }));

  const CustomMenuList = (props) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <div
          style={{
            padding: "10px",
            paddingBottom: 0,
            textAlign: "center",
            fontWeight: "bold",
            cursor: "pointer",
            borderTop: "1px solid #ddd",
          }}
          onClick={() => props.selectProps.onChange([])}
        >
          Clear All
        </div>
      </components.MenuList>
    );
  };

  const customStyles = {
    ...styles(props.size, props.error, props.success),
    valueContainer: () => ({
      ...styles(props.size, props.error, props.success).valueContainer(),
      width: props.isMulti ? "180px" : "100%",
      // width: "100%",
    }),
  };

  const CustomValueContainer = ({ children, getValue, selectProps, ...rest }) => {
    const selectedValues = getValue();
    const count = selectedValues.length;

    return (
      <components.ValueContainer {...rest}>
        <span
          onMouseDown={(e) => {
            e.stopPropagation();
            if (selectProps.menuIsOpen) {
              selectProps.onMenuClose();
            } else {
              selectProps.onMenuOpen();
            }
          }}
        >
          {props.placeholder}
        </span>
        {count >= 1 && (
          <div
            onMouseDown={(e) => {
              e.stopPropagation();
              if (selectProps.menuIsOpen) {
                selectProps.onMenuClose();
              } else {
                selectProps.onMenuOpen();
              }
            }}
            style={{
              backgroundColor: "#f4f4f5",
              padding: "3px 3px 3px 6px",
              borderRadius: "0.5rem",
              fontSize: "85%",
              color: "#18181b",
            }}
          >
            {`${count} Selected`}
          </div>
        )}
      </components.ValueContainer>
    );
  };

  const selectProps = {
    ...props,
    isClearable: false,
    styles: customStyles,
    theme: (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: light.main,
        primary: light.main,
      },
    }),
    options: props.allowSelectAll
      ? formatOptions([props.allOption, ...props.options])
      : formatOptions(props.options),
    value: allSelected && props.allowSelectAll ? [props.allOption] : props.value,
    onChange: (selected) => {
      if (selected.length > 0 && selected[selected.length - 1].value === props.allOption.value) {
        return props.onChange(props.options);
      }
      if (selected.some((item) => item.value === clearAllOption.value)) {
        return props.onChange([]);
      }
      return props.onChange(selected);
    },
    components: {
      ValueContainer: CustomValueContainer,
      MenuList: CustomMenuList,
    },
  };

  return <ReactSelect {...selectProps} />;
};

MultiSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  allowSelectAll: PropTypes.bool,
  isMulti: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};

MultiSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  allowSelectAll: false,
  isMulti: false,
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default MultiSelect;
