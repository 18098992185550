import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import CampaignCard from "./CampaignCard";

const CampaignList = ({ campaigns }) => (
  <Grid sx={{ padding: 0, height: 500, overflowY: "auto" }}>
    {Object.entries(campaigns).map(([campaignName, campaignData]) => (
      <CampaignCard key={campaignName} name={campaignName} data={campaignData} />
    ))}
  </Grid>
);

CampaignList.propTypes = {
  campaigns: PropTypes.object.isRequired,
};

export default CampaignList;
