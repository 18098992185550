import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function TickerSymbolIcon({ ...props }) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 30">
      <rect width="48" height="24" rx="4" />
      <polyline points="5,20 15,10 25,15 35,5 45,10" fill="none" stroke="white" strokeWidth="2" />
    </SvgIcon>
  );
}

export default TickerSymbolIcon;
