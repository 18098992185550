import React, { useState } from "react";
import {
  Card,
  Typography,
  Button,
  Box,
  Avatar,
  Grid,
  Checkbox,
  Divider,
  Link,
  CircularProgress,
} from "@mui/material";

import PropTypes from "prop-types";
import { Language, Business, People, AttachMoney, CalendarToday } from "@mui/icons-material";
import TickerSymbolIcon from "../../../icons/ticker_symbol";
import { formatNumber, renderSocialIcons } from "../../helper";

const joinArr = (arr) => arr.filter((i) => i !== null).join(", ");

const websiteUrl = (emailDomain) => {
  if (emailDomain) {
    return emailDomain.includes("http") ? emailDomain : `https://${emailDomain}`;
  }
  return null;
};

const NonEnriched = ({ data, onViewDetails, onFindContacts }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async (id) => {
    setLoading(true);
    await onViewDetails(id); // Assuming this is an async function
  };

  return (
    <Card
      sx={{
        p: 3,
        border: "1px solid #e0e0e0",
        borderRadius: 2,
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        position: "relative",
        overflow: "hidden",
        transition: "box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out",
        "&::before": {
          content: '""',
          position: "absolute",
          width: "220%", // Keep the width as before or adjust based on your preference
          height: "150px",
          backgroundColor: "rgba(197, 197, 197, 0.3)",
          transform: "rotate(-10deg)",
          top: "10px",
          right: "10px",
        },
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={1.8} sx={{ display: "flex", alignItems: "flex-start" }}>
          <Checkbox id={data.id} key={data.id} size="small" sx={{ mr: 1 }} />
          <Avatar
            src={data.logo_url}
            alt={data.name}
            variant="rounded"
            sx={{ width: 56, height: 56 }}
          />
        </Grid>

        <Grid item xs={12} sm={10.2}>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Typography variant="h6" fontWeight={500} color="primary">
                {data.name}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                {data.description}
              </Typography>

              <Box sx={{ display: "flex", mt: 1, gap: 0.5 }}>
                {renderSocialIcons({ ...data.links, linkedin: data.linkedin_url })}
              </Box>
            </Grid>

            <Grid item xs={12} sm={4} sx={{ textAlign: { sm: "right" } }}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Language fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />
                <Typography variant="body2" color="primary">
                  {data.email_domain && (
                    <Link href={websiteUrl(data.email_domain)} target="_new">
                      {data.email_domain}
                    </Link>
                  )}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                {joinArr([data.city, data.region, data.country_code])}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", gap: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Business fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />
                <Typography variant="body2">{data.industry_str || "-"}</Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <People fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />
                <Typography variant="body2">{data.employee_count}</Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AttachMoney fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />
                <Typography variant="body2">
                  {data.revenue ? formatNumber(data.revenue) : " - "}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TickerSymbolIcon
                  symbol={data.ticker_symbol}
                  fontSize="small"
                  sx={{ color: "text.secondary", mr: 1 }}
                />
                <Typography variant="body2">{data.ticker_symbol || " - "}</Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CalendarToday fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />
                <Typography variant="body2">{data.founded || " - "}</Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              {/* <Chip
              label={`${data.contacts || " - "} contacts`}
              size="small"
              sx={{
                backgroundColor: "rgba(58, 123, 213, 0.1)",
                color: "rgba(255, 77, 0, 0.85)",
              }}
            /> */}

              {data.hasContacts ? (
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "rgba(58, 123, 213, 0.1)",
                    borderColor: "transparent",
                    color: "rgba(255, 77, 0, 0.85)",
                    "&:hover": {
                      backgroundColor: "rgba(58, 123, 213, 0.2)",
                      borderColor: "transparent",
                    },
                  }}
                >
                  View Contacts
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={() => onFindContacts(data.id)}>
                  Find Contacts
                </Button>
              )}

              <Button
                variant="outlined"
                disabled={loading}
                sx={{
                  color: "rgba(255, 77, 0, 0.85)",
                  minWidth: "auto",
                  textTransform: "none",
                  boxShadow: "none",
                }}
                onClick={() => handleClick(data.id)}
              >
                {loading ? (
                  <CircularProgress size={20} sx={{ color: "rgba(255, 77, 0, 0.85)" }} />
                ) : (
                  "Get More Info"
                )}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

NonEnriched.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  onViewDetails: PropTypes.func.isRequired,
  onFindContacts: PropTypes.func.isRequired,
};

export default NonEnriched;
