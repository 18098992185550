/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import axios from "axios";
import Swal from "sweetalert2";
import ROICardChart from "examples/Charts/AnayliticsChart/ROiCardChart";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SuiTypography from "components/SuiTypography";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "10px",
          backgroundColor: "rgba(237, 108, 2, 1)",
          transition: "transform 0.1s ease",
          width: "100px",
          "&:hover": {
            backgroundColor: "rgb(255 138 43)",
            transform: "scale(1.1)",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          textAlign: "center",
        },
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  descBtn: {
    color: "#6b7280",
    width: "20px",
    height: "20px",
    cursor: "pointer",
  },
  input: {
    maxWidth: "100px",
  },
  sendButton: {
    border: "0",
    cursor: "pointer",
  },
  labelAndInput: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 320px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  btn: {
    // display: "flex",
    // justifyContent: "center",
    // paddingRight: "50px",
    "@media (max-width: 900px)": {
      paddingRight: "10px",
    },
    "@media (max-width: 320px)": {
      paddingRight: "50px",
    },
  },
}));

function SimpleTabInfo({ customerUser }) {
  const [roiData, setRoiData] = useState(null);
  const [totalDeals, setTotalDeals] = useState(0);
  const [offers, setOffers] = useState(0);
  const [appointment, setAppointment] = useState(0);
  const [followUps, setFollowUps] = useState(0);
  const [price, setPrice] = useState(0);
  const [averageDeals, setAverageDeals] = useState(0);
  const [cardInfo, setCardInfo] = useState([]);
  const isSubmitDisabled = [price, appointment, averageDeals, followUps, offers, totalDeals].some(
    (value) => value === "" || value === null
  );
  // const isSubmitDisabled =
  //   !price || !appointment || !averageDeals || (!followUps && !offers && !totalDeals);

  const classes = useStyles();
  const cardData = (data = null) => {
    setCardInfo([
      {
        label: "Campaign Lead to Deal",
        desc: "The percentage of leads generated from a campaign that convert into deals. This metric helps evaluate the effectiveness of a campaign in driving sales conversions.",
        progress: {
          content: data ? `${data?.statistic?.["cr_campaign_lead_to_deal_%"]}%` : "0%",
        },
      },
      {
        label: "Campaign Lead to Follow Up",
        desc: "The percentage of leads from a campaign that move to the follow-up stage. It measures the initial engagement effectiveness and how well leads are being nurtured.",
        progress: {
          content: data ? `${data?.statistic?.["cr_campaign_lead_to_follow_up_%"]}%` : "0%",
        },
      },
      {
        label: "Campaign Lead to Offer",
        desc: "The percentage of campaign leads that progress to receiving an offer. This metric assesses the quality of leads and their movement through the sales funnel.",
        progress: {
          content: data ? `${data?.statistic?.["cr_campaign_lead_to_offer_%"]}%` : "0%",
        },
      },
      {
        label: "Follow Up to Deal",
        desc: "The percentage of follow-ups that result in a deal. This metric indicates the effectiveness of follow-up strategies in closing sales.",
        progress: {
          content: data ? `${data?.statistic?.["cr_follow_up_to_deal_%"]}%` : "0%",
        },
      },
      {
        label: "Follow Up to Offer",
        desc: "The percentage of follow-ups that progress to an offer being made. It shows how effective the follow-up process is in advancing leads further down the sales funnel.",
        progress: {
          content: data ? `${data?.statistic?.["cr_follow_up_to_offer_%"]}%` : "0%",
        },
      },
      {
        label: "Offer To Deal",
        desc: "The percentage of offers that convert into deals. This metric measures the success rate of converting proposals into actual sales.",
        progress: {
          content: data ? `${data?.statistic?.["cr_offer_to_deal_%"]}%` : "0%",
        },
      },
      {
        label: "Profitability Rating",
        desc: "A rating that evaluates the profitability of the marketing campaigns based on revenue generated versus cost incurred. It provides an overall assessment of campaign efficiency.",
        progress: {
          content: data
            ? `${data?.statistic?.profitability_rating} (${data?.statistic?.profitability_good_or_bad})`
            : "0%",
        },
      },
      {
        label: "Predictive Deal",
        desc: "An estimation of potential deals that are likely to close based on current pipeline data and predictive analytics. This helps in forecasting sales outcomes.",
        progress: {
          content: data ? data?.statistic?.predictive_deal : "0%",
        },
      },
      {
        label: "Net Profit",
        desc: "The percentage of total revenue that remains as profit after all expenses, including marketing, sales, and operational costs, are subtracted. This metric provides insight into the overall profitability and efficiency of the marketing efforts relative to the revenue generated.",
        progress: {
          currency: `€`,
          content: data ? `${data?.statistic?.net_profit_10_p}` : "0%",
        },
      },
      {
        label: "Customer Acquisition Cost",
        desc: "The total cost of acquiring a new customer, including marketing and sales expenses. Lower costs indicate more efficient marketing efforts.",
        progress: {
          content: data ? `€${data?.statistic?.customer_ac_cost}` : "0%",
        },
      },
      {
        label: "Total Revenue",
        desc: "The total revenue generated from all marketing campaigns. It reflects the overall financial impact of marketing efforts.",
        progress: {
          content: data ? `€${data?.statistic?.total_revenue}` : "0%",
        },
      },
      {
        label: "ROI",
        desc: "The Return on Investment (ROI) from marketing activities. This metric shows the profitability of marketing campaigns relative to their costs.",
        progress: {
          content: data ? data?.statistic?.roi : "0%",
        },
      },
    ]);
  };

  useEffect(() => {
    cardData();
  }, []);
  const handleStaticROIInfo = () => {
    const payload = {
      price,
      total_appointments: appointment,
      total_follow_ups: followUps,
      total_offers: offers,
      total_deals: totalDeals,
      average_deal_size_in_eur: averageDeals,
      real_values: false,
      campaign_type: "appointments",
    };
    axios
      .post(`/api/customers/${customerUser?.customer.id}/kpi/roi/state`, payload)
      .then((res) => {
        setRoiData(res?.data);
        cardData(res?.data);
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };
  const priceDesc = "Enter the price";
  const avgDesc = "Enter the average deal size in dollars";
  const totalAppDesc =
    "Enter the total number of leads generated through the specified type of campaign (e.g., email, social media, or direct mail). This metric provides insight into the volume of leads entering the sales funnel from a specific source.";
  const totalFollowUpDesc =
    "Enter the total number of follow-up actions taken with leads. This metric helps assess the persistence and effectiveness of efforts to engage potential customers.";
  const totalOffersDesc =
    "Enter the total number of offers made to leads. This measures how many potential deals reached the stage of receiving a formal proposal or offer.";
  const totalDealsDesc = "Enter the total Deals";

  return (
    <>
      <div>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card
              sx={{
                border: "1px solid #d1d5db",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "none",
                marginTop: "10px",
                // backgroundColor: "transparent",
              }}
            >
              <ThemeProvider theme={theme}>
                <Box mr={{ sm: 0, xs: 0, md: 4 }}>
                  <div>
                    <Grid style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                      <Typography>Price</Typography>
                      <Tooltip title={priceDesc} arrow>
                        <InfoOutlinedIcon className={classes.descBtn} />
                      </Tooltip>
                    </Grid>
                    <Stack
                      direction="row"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "2.5%",
                        border: "1px solid #e4e4e7",
                        width: "80%",
                        "&:hover": {
                          border: "1px solid #a1a1aa",
                        },
                      }}
                      alignItems="center"
                    >
                      <input
                        type="number"
                        defaultValue={price}
                        style={{
                          border: "0",
                          background: "white",
                          outline: "none",
                          width: "100%",
                        }}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          setPrice(value >= 0 ? value : 0);
                        }}
                      />
                    </Stack>
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <Grid style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                      <Typography>Average Deal Size Eur</Typography>
                      <Tooltip title={avgDesc} arrow>
                        <InfoOutlinedIcon className={classes.descBtn} />
                      </Tooltip>
                    </Grid>
                    <Stack
                      direction="row"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "2.5%",
                        border: "1px solid #e4e4e7",
                        width: "80%",
                        "&:hover": {
                          border: "1px solid #a1a1aa",
                        },
                      }}
                      alignItems="center"
                    >
                      <input
                        type="number"
                        defaultValue={averageDeals}
                        style={{
                          border: "0",
                          background: "white",
                          outline: "none",
                          width: "100%",
                        }}
                        placeholder="Enter Average Deal Size Eur"
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value >= 0) {
                            setAverageDeals(value);
                          } else {
                            e.target.value = 0;
                          }
                        }}
                      />
                    </Stack>
                  </div>
                </Box>
              </ThemeProvider>
            </Card>
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card
              sx={{
                border: "1px solid #d1d5db",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "none",
                marginTop: "10px",
              }}
            >
              <ThemeProvider theme={theme}>
                <Box mr={{ sm: 0, xs: 0, md: 4 }}>
                  <div>
                    <Grid style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                      <Typography>Total Follow Ups</Typography>
                      <Tooltip title={totalFollowUpDesc} arrow>
                        <InfoOutlinedIcon className={classes.descBtn} />
                      </Tooltip>
                    </Grid>
                    <Stack
                      direction="row"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "2.5%",
                        border: "1px solid #e4e4e7",
                        width: "80%",
                        "&:hover": {
                          border: "1px solid #a1a1aa",
                        },
                      }}
                      alignItems="center"
                    >
                      <input
                        type="number"
                        defaultValue={followUps}
                        style={{
                          border: "0",
                          background: "white",
                          outline: "none",
                          width: "100%",
                        }}
                        placeholder="Enter Total Follow Ups"
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          setFollowUps(value >= 0 ? value : 0);
                        }}
                      />
                    </Stack>
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <Grid style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                      <Typography>Total Appointments</Typography>
                      <Tooltip title={totalAppDesc} arrow>
                        <InfoOutlinedIcon className={classes.descBtn} />
                      </Tooltip>
                    </Grid>
                    <Stack
                      direction="row"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "2.5%",
                        border: "1px solid #e4e4e7",
                        width: "80%",
                        "&:hover": {
                          border: "1px solid #a1a1aa",
                        },
                      }}
                      alignItems="center"
                    >
                      <input
                        type="number"
                        defaultValue={appointment}
                        style={{
                          border: "0",
                          background: "white",
                          outline: "none",
                          width: "100%",
                        }}
                        placeholder="Enter Appointment"
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          setAppointment(value >= 0 ? value : 0);
                        }}
                      />
                    </Stack>
                  </div>
                </Box>
              </ThemeProvider>
            </Card>
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card
              sx={{
                border: "1px solid #d1d5db",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "none",
                marginTop: "10px",
              }}
            >
              <ThemeProvider theme={theme}>
                <Box mr={{ sm: 0, xs: 0, md: 4 }}>
                  <div>
                    <Grid style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                      <Typography>Total Offers</Typography>
                      <Tooltip title={totalOffersDesc} arrow>
                        <InfoOutlinedIcon className={classes.descBtn} />
                      </Tooltip>
                    </Grid>
                    <Stack
                      direction="row"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "2.5%",
                        border: "1px solid #e4e4e7",
                        width: "80%",
                        "&:hover": {
                          border: "1px solid #a1a1aa",
                        },
                      }}
                      alignItems="center"
                    >
                      <input
                        type="number"
                        defaultValue={offers}
                        style={{
                          border: "0",
                          background: "white",
                          outline: "none",
                          width: "100%",
                        }}
                        placeholder="Enter Offers"
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          setOffers(value >= 0 ? value : 0);
                        }}
                      />
                    </Stack>
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <Grid style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                      <Typography>Total Deals</Typography>
                      <Tooltip title={totalDealsDesc} arrow>
                        <InfoOutlinedIcon className={classes.descBtn} />
                      </Tooltip>
                    </Grid>
                    <Stack
                      direction="row"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "2.5%",
                        border: "1px solid #e4e4e7",
                        width: "80%",
                        "&:hover": {
                          border: "1px solid #a1a1aa",
                        },
                      }}
                      alignItems="center"
                    >
                      <input
                        type="number"
                        defaultValue={totalDeals}
                        style={{
                          border: "0",
                          background: "white",
                          outline: "none",
                          width: "100%",
                        }}
                        placeholder="Enter Offers"
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          setTotalDeals(value >= 0 ? value : 0);
                        }}
                      />
                    </Stack>
                  </div>
                </Box>
              </ThemeProvider>
            </Card>
          </Grid>
        </Grid>
        <Grid
          display={{ md: "flex", lg: "flex", sm: "block" }}
          alignItems="center"
          textAlign="center"
          marginTop="10px"
          px={2}
        >
          <Grid className={classes.btn}>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                disabled={isSubmitDisabled}
                onClick={handleStaticROIInfo}
                style={{
                  color: "#ffffff",
                  backgroundColor: "#ff4d00d9",
                  minWidth: "135px",
                  minHeight: "40px",
                  fontWeight: "bold",
                  fontSize: "13px",
                  borderRadius: "7px",
                }}
              >
                Calculate ROI
              </Button>
            </ThemeProvider>
          </Grid>
          {roiData?.statistic?.advice && (
            <Card
              sx={{
                border: "1px solid #d1d5db",
                padding: "10px",
                borderRadius: "10px",
                boxShadow: "none",
                margin: "10px",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 1,
                  padding: "7px !important",
                }}
              >
                <SuiTypography>
                  <Grid item md={12}>
                    <Typography variant="h6" fontSize={14}>
                      ADVICE: {roiData?.statistic?.advice}
                    </Typography>
                  </Grid>
                </SuiTypography>
              </CardContent>
            </Card>
          )}
        </Grid>

        {/* {cardInfo.length !== 0 && ( */}
        <ThemeProvider theme={theme}>
          <Grid item xs={12} md={12} mt={1.5} paddingBottom={{ sm: 4, xs: 12 }}>
            <ROICardChart color="#444" title="" chart={{}} items={cardInfo} />
          </Grid>
        </ThemeProvider>
        {/* )} */}
      </div>
    </>
  );
}

export default SimpleTabInfo;
SimpleTabInfo.propTypes = {
  customerUser: PropTypes.any.isRequired,
};
