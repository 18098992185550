/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography, Divider, Chip, IconButton, Tooltip } from "@mui/material";
import { Email, Phone, Twitter, LinkedIn, Business } from "@mui/icons-material";
import { renderSocialIcons } from "../../helper";

const Enriched = ({ data }) => (
  <Grid container spacing={3} sx={{ p: 2 }}>
    {/* Header Section */}
    <Grid item xs={12}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5" fontWeight="bold">
            {data.current_title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {data.current_employer}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {data.location}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {renderSocialIcons({ linkedin: data.current_employer_linkedin_url })}
          </Typography>
        </Box>
        <Box>
          {data.links?.twitter && (
            <Tooltip title="Twitter" arrow>
              <IconButton href={data.links.twitter} target="_blank" size="small">
                <Twitter />
              </IconButton>
            </Tooltip>
          )}
          {data.links?.linkedin && (
            <Tooltip title="LinkedIn" arrow>
              <IconButton href={data.links.linkedin} target="_blank" size="small">
                <LinkedIn />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Grid>

    <Grid item xs={12}>
      <Divider />
    </Grid>

    {/* Job History Section */}
    <Grid item xs={12}>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Job History
      </Typography>
      {data.job_history.length > 0 ? (
        data.job_history.map((job, index) => (
          <Box key={index} sx={{ mb: 3 }}>
            <Typography variant="body1" fontWeight="bold">
              {job.title} @ {job.company}
              {job.company_linkedin_url && (
                <IconButton href={data.company_linkedin_url} target="_blank" size="small">
                  <LinkedIn />
                </IconButton>
              )}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {job.start_date} - {job.end_date || "Present"}{" "}
            </Typography>
            {job.description && (
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                {job.description}
              </Typography>
            )}
            {job.highest_level && (
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                {job.highest_level}
              </Typography>
            )}
          </Box>
        ))
      ) : (
        <Typography variant="body2" color="textSecondary">
          No job history available.
        </Typography>
      )}
    </Grid>

    <Grid item xs={12}>
      <Divider />
    </Grid>

    {/* Contact Information (Responsive Layout) */}
    <Grid item xs={12}>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Contact Information
      </Typography>
      <Grid container spacing={2}>
        {/* Emails */}
        <Grid item xs={12} md={6}>
          {data.emails.length > 0 ? (
            data.emails.map(
              (emailObj, index) =>
                emailObj.smtp_valid === "valid" && (
                  <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    {emailObj.type === "professional" && (
                      <Business sx={{ mr: 1, color: "rgba(255, 77, 0, 0.85)" }} />
                    )}
                    {emailObj.type === "personal" && (
                      <Email sx={{ mr: 1, color: "rgba(255, 77, 0, 0.85)" }} />
                    )}
                    <Typography variant="body2">
                      <strong>{emailObj.email}</strong> ({emailObj.type})
                    </Typography>
                  </Box>
                )
            )
          ) : (
            <Typography variant="body2" color="textSecondary">
              No email available.
            </Typography>
          )}
        </Grid>

        {/* Phone Numbers */}
        <Grid item xs={12} md={6}>
          {data.phones?.length > 0 ? (
            data.phones.map((phoneObj, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Phone sx={{ mr: 1, color: "rgba(255, 77, 0, 0.85)" }} />
                <Typography variant="body2">
                  <strong>{phoneObj.number}</strong> ({phoneObj.type})
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No phone number available.
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>

    <Grid item xs={12}>
      <Divider />
    </Grid>

    {/* Skills Section */}
    <Grid item xs={12}>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Skills
      </Typography>
      {data.skills.length > 0 ? (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {data.skills.map((skill, index) => (
            <Chip key={index} label={skill} variant="outlined" sx={{ fontWeight: "500" }} />
          ))}
        </Box>
      ) : (
        <Typography variant="body2" color="textSecondary">
          No skills listed.
        </Typography>
      )}
    </Grid>
  </Grid>
);

Enriched.propTypes = {
  data: PropTypes.shape({
    current_title: PropTypes.string,
    current_employer: PropTypes.string,
    location: PropTypes.string,
    links: PropTypes.object,
    job_history: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        company: PropTypes.string,
        start_date: PropTypes.string,
        end_date: PropTypes.string,
        description: PropTypes.string,
      })
    ),
    emails: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    phones: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    skills: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default Enriched;
