import { useContext, useEffect, useState } from "react";
import { useSoftUIController } from "context";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Alert, Box, CircularProgress, Grid, Stack } from "@mui/material";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/Navbar";
import { CustomerUserContext } from "App";
import SearchHeader from "./components/SearchHeader";
import ProspectFilterSidebar from "./components/people/ProspectFilterSidebar";
import CompanyCard from "./components/companies/CompanyCard";
import ProspectCard from "./components/people/ProspectCard";
import {
  searchPeople,
  searchCompanies,
  lookupPerson,
  lookupCompany,
  // checkPersonStatus,
} from "./services/rocketReachApi";
import NoRecordFound from "./components/NoRecordFound";
import WelcomeToProspect360 from "./WelcomeToProspect360";
import useDebounce from "./components/helper";
import CompanyFilterSidebar from "./components/companies/CompanyFilterSidebar";
// Custom theme for components
const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "lightgrey",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
        },
        containedPrimary: {
          background:
            "linear-gradient(90deg,rgba(255, 77, 0, 0.92) 0%,rgba(255, 91, 15, 0.97) 100%)",
          "&:hover": {
            background:
              "linear-gradient(90deg,rgba(255, 91, 15, 0.97)  0%, rgba(255, 77, 0, 0.93) 100%)",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
          transition: "box-shadow 0.3s ease",
          "&:hover": {
            boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "rgb(237, 77, 49)",
    },
    secondary: {
      main: "#f5f7fa",
    },
    background: {
      default: "#FBFAFB",
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    button: {
      fontWeight: 500,
    },
  },
});

function Prospect360() {
  const [customerUser] = useContext(CustomerUserContext);
  const [controller] = useSoftUIController();
  const [tabValue, setTabValue] = useState(0);
  const [filterReset, setFilterReset] = useState(false);
  const [orderBy, setOrderBy] = useState("Relevance");
  const [query, setQuery] = useState();
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [filtersx, setFilters] = useState({});
  const filters = useDebounce(filtersx, 1500);
  const [pageSize, setPageSize] = useState(10);
  const [findContact, setFindContact] = useState(false);

  const handleSearchFunc = async () => {
    if (!(filters || query)) return;

    let q = {};
    if (query) {
      q.keyword = tabValue === 0 ? query : [query];
    }
    if (filters) {
      q = { ...filters, ...q };
    }
    try {
      const searchFunction = tabValue === 0 ? searchPeople : searchCompanies;
      if (Object.keys(q).length > 0) {
        const response = await searchFunction(customerUser.customer.id, q, page, pageSize, orderBy);
        setResults(response);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    if (!(filters || query)) return;

    setLoading(true);
    setError(null);

    await handleSearchFunc();
  };

  const onFindContacts = async (id) => {
    setFindContact(true);
    setFilters({ company_id: [id] });
    setTabValue(0);
    setPage(1);
  };

  const handleLookup = async (id) => {
    // setLoading(true);
    // setError(null);
    try {
      const lookupFunction = tabValue === 0 ? lookupPerson : lookupCompany;
      const response = await lookupFunction(customerUser.customer.id, { id });
      // Handle the detailed lookup response as needed
      console.log("Lookup response:", response);
    } catch (err) {
      setError(err.message);
    } finally {
      // setLoading(false);
      await handleSearchFunc();
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(1); // Reset to first page when filters change
    // handleSearch();
  };

  useEffect(() => {
    if (query || Object.keys(filters).length > 0) {
      handleSearch();
    }
  }, [page, filters, orderBy, pageSize]);

  useEffect(() => {
    if (
      (!filters.company_id && !findContact) ||
      tabValue === 1 ||
      (filters.company_id && tabValue === 0)
    ) {
      setPage(1);
      setQuery("");
      setResults(null);
      setFilters({});
    }
  }, [tabValue, filterReset]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar componentName="Prospect360" />
      </DashboardLayout>
      <Grid
        item
        container
        sx={{ backgroundColor: "#FBFAFB", overflow: "hidden" }}
        ml={controller.miniSidenav ? "100px" : "300px"}
        md={controller.miniSidenav ? 10.8 : 9}
      >
        <ThemeProvider theme={theme}>
          <SearchHeader
            tabValue={tabValue}
            setTabValue={setTabValue}
            searchQuery={query}
            setSearchQuery={setQuery}
            handleSearch={handleSearch}
            loading={loading}
            results={results}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
            page={page}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
          />
          {/* Main Content */}
          <Grid item container spacing={2}>
            {tabValue === 0 ? (
              <ProspectFilterSidebar
                filters={filters}
                onFilterChange={handleFilterChange}
                onFilterReset={() => setFilterReset(!filterReset)}
              />
            ) : (
              <CompanyFilterSidebar
                filters={filters}
                onFilterChange={handleFilterChange}
                onFilterReset={() => setFilterReset(!filterReset)}
              />
            )}
            <Grid item xs={12} md={9}>
              {error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  {error}
                </Alert>
              )}

              <Box sx={{ mb: 3 }}>
                {loading ? (
                  <Box display="flex" justifyContent="center" p={4}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Grid container spacing={2} style={{ maxHeight: "65vh", overflowY: "auto" }}>
                    {tabValue === 0
                      ? results &&
                        results.profiles &&
                        results.profiles.map((result) => (
                          <Grid item xs={12} key={result.id}>
                            <ProspectCard
                              data={result}
                              onEnrichDetails={() => handleLookup(result.id)}
                            />
                          </Grid>
                        ))
                      : results &&
                        results.companies &&
                        results.companies.map((result) => (
                          <Grid item xs={12} key={result.id}>
                            <CompanyCard
                              onFindContacts={onFindContacts}
                              data={result}
                              onEnrichDetails={() => handleLookup(result.id)}
                            />
                          </Grid>
                        ))}
                  </Grid>
                )}
                {!loading && results && results.pagination && results.pagination.total === 0 && (
                  <Box md={12} pt={2}>
                    <NoRecordFound />
                  </Box>
                )}
                {!loading && results === null && (
                  <Box md={12} pt={2}>
                    <WelcomeToProspect360 />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>

          <Stack sx={{ width: "100%", flexShrink: "0", mt: 4 }}>
            <Footer />
          </Stack>
        </ThemeProvider>
      </Grid>
    </>
  );
}

export default Prospect360;
