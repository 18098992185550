import React, { useEffect, useState } from "react";
import { Card, Typography, Button, Avatar, Box, Grid, Checkbox, Link } from "@mui/material";
import PropTypes from "prop-types";
import { CalendarToday, Email } from "@mui/icons-material";
import ProspectEnrichedProfileCard from "../detail/Enriched";
import { renderEmailSection, renderPhoneSection, renderSocialIcons } from "../../helper";

const Enriched = ({ data, id }) => {
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    if (data.id === id) {
      setViewMore(true);
    }
  }, [id]);

  let email = null;
  if (data.emails.length > 0) {
    if (data.emails.filter((i) => i.type === "professional")) {
      email = data.emails.filter((i) => i.type === "professional")[0].email;
    }
    if (!email) {
      email = data.emails[0].email;
    }
  }
  return (
    <Card
      sx={{
        p: 3,
        border: "1px solid #e0e0e0",
        borderRadius: 2,
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        transition: "box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out",
        "&:hover": {
          borderColor: "rgba(255, 77, 0, 0.85)",
          boxShadow: "0px 8px 30px rgba(255, 77, 0, 0.3)",
        },
      }}
    >
      <Grid container>
        <Grid item sx={{ width: "30px", display: "flex", alignItems: "flex-start", mr: 2 }}>
          <Checkbox size="small" sx={{ mr: 2 }} />
        </Grid>

        <Grid item xs>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Avatar
                  src={data.profile_pic}
                  alt={data.name}
                  sx={{ width: 56, height: 56, mr: 2 }}
                />
                <Box>
                  <Typography variant="h6" fontWeight={500}>
                    {data.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data.current_title}
                  </Typography>
                  {data.birth_year && (
                    <Box sx={{ display: "flex", mt: 1, alignItems: "center" }}>
                      <CalendarToday fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />
                      <Typography variant="body2">Birth Year: {data.birth_year}</Typography>
                    </Box>
                  )}
                  <Box sx={{ display: "flex", mt: 1, gap: 0.5 }}>
                    {renderSocialIcons({ ...data.links, linkedin: data.linkedin_url })}
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ textAlign: { sm: "right" } }}>
              <Typography variant="subtitle1" color="primary" fontWeight={500}>
                <Link
                  href={data.current_employer_website || `https://${data.current_employer_domain}`}
                  target="_new"
                >
                  {data.current_employer}
                </Link>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {data.location}
              </Typography>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
            <Box>
              {data.current_work_email &&
                renderEmailSection("B", [data.recommended_professional_email])}
              {data.phones && renderPhoneSection(data.phones)}
              {data.recommended_email && renderEmailSection("P", [data.recommended_personal_email])}
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 2 }}
            >
              {email ? (
                <Button
                  variant="outlined"
                  startIcon={<Email />}
                  href={`mailto:${email}`}
                  sx={{
                    backgroundColor: "rgba(58, 123, 213, 0.1)",
                    borderColor: "transparent",
                    color: "rgba(255, 77, 0, 0.85)",
                    "&:hover": {
                      backgroundColor: "rgba(58, 123, 213, 0.2)",
                      borderColor: "transparent",
                    },
                  }}
                >
                  Send Email
                </Button>
              ) : (
                <Button variant="contained" color="primary">
                  Get Contact Info
                </Button>
              )}
              <Button
                sx={{
                  color: "rgba(255, 77, 0, 0.85)",
                  minWidth: "auto",
                  textTransform: "none",
                  boxShadow: "none",
                  alignSelf: "center",
                }}
                onClick={() => setViewMore(!viewMore)}
              >
                View More
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid sx={{ display: viewMore ? "block" : "none" }}>
        {data.is_enriched && <ProspectEnrichedProfileCard data={data.original_response} />}
      </Grid>
    </Card>
  );
};

Enriched.defaultProps = {
  id: null,
};

Enriched.propTypes = {
  id: PropTypes.number,
  data: PropTypes.object.isRequired,
};

export default Enriched;
