import React, { useState } from "react";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from "prop-types";

const UniversalDropdown = ({ options = [], defaultValue, onChange, label }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(defaultValue);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    if (option !== undefined) {
      setSelected(option);
      if (onChange) {
        onChange(option);
      }
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "8px 16px",
          color: "#666",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        {label && (
          <Typography variant="body2" sx={{ mr: 1 }}>
            {label}:
          </Typography>
        )}
        <Typography variant="body2">{selected}</Typography>
        <KeyboardArrowDownIcon fontSize="small" sx={{ ml: 1 }} />
      </Box>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleClose()}>
        {options.map((option, index) => {
          // Support both object and string options
          const value = typeof option === "object" && option !== null ? option.value : option;
          const display = typeof option === "object" && option !== null ? option.label : option;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={index} onClick={() => handleClose(value)}>
              {display}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

UniversalDropdown.propTypes = {
  options: PropTypes.objectOf(PropTypes.object).isRequired,
  defaultValue: PropTypes.object.isRequired,
  onChange: PropTypes.objectOf(PropTypes.func).isRequired,
  label: PropTypes.string.isRequired,
};

export default UniversalDropdown;
