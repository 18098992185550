/* eslint-disable no-shadow */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Enriched from "./main/Enriched";
import NonEnriched from "./main/NonEnriched";

const CompanyCard = ({ data, onEnrichDetails, onFindContacts }) => {
  const [id, setId] = useState(null);
  const handleViewDetails = (pid) => {
    onEnrichDetails(pid);
    setId(pid);
  };

  if (data.is_enriched) {
    return <Enriched data={data} id={id} onFindContacts={onFindContacts} />;
  }
  return (
    <NonEnriched data={data} onViewDetails={handleViewDetails} onFindContacts={onFindContacts} />
  );
};

CompanyCard.propTypes = {
  data: PropTypes.object.isRequired,
  onEnrichDetails: PropTypes.func,
  onFindContacts: PropTypes.func,
};

CompanyCard.defaultProps = {
  onEnrichDetails: async () => {},
  onFindContacts: async () => {},
};

export default CompanyCard;
