"use client";

import { useState, useEffect } from "react";
import {
  Typography,
  Divider,
  FormControl,
  TextField,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  IconButton,
  Box,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  Collapse,
  Grid,
  Card,
  Slider,
  Chip,
  Button,
  Switch,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import PropTypes from "prop-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import locationData from "./locationData";
import companyFilterCategories from "./companyFilterCategories";

const CompanyFilterSidebar = ({ filters, onFilterChange, filterReset, onFilterReset }) => {
  // Manage main accordions and subaccordions independently using arrays.
  const [expanded, setExpanded] = useState([]);
  const [expandedChildren, setExpandedChildren] = useState([]);
  const [expandedLocations, setExpandedLocations] = useState({});
  const [locationSearch, setLocationSearch] = useState("");
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [distance, setDistance] = useState(0);
  const [enriched, setEnriched] = useState(false);

  // Local state for input field values
  const [localValues, setLocalValues] = useState({});
  // Local state for checkbox values
  const [localCheckboxes, setLocalCheckboxes] = useState({});

  // When filters change externally, clear local input fields
  useEffect(() => {
    setLocalValues({});
    setLocalCheckboxes({});
  }, [filters]);

  const aDistance = (item) => {
    if (distance > 0) {
      return `${item.split("::")[0]}::~${distance}km`;
    }
    return item.split("::")[0];
  };

  const applyDistance = (geo) => geo.map((item) => aDistance(item));

  const handleSliderChange = (event, newValue) => {
    setDistance(newValue);
    if (filters.geo) {
      onFilterChange({
        ...filters,
        geo: applyDistance(filters.geo),
      });
    }
    if (filters.location) {
      onFilterChange({
        ...filters,
        location: applyDistance(filters.location),
      });
    }
  };

  // Update local state on field changes (for all inputs)
  const handleLocalChange = (field) => (event) => {
    const newValue = event.target.value;
    setLocalValues((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  // Update local state for checkboxes
  const handleLocalCheckboxChange = (field) => (event) => {
    const newValue = event.target.checked;
    setLocalCheckboxes((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  // Append the field value to the filters array and clear the field
  const handleFieldSubmit = (field) => () => {
    const inputValue = localValues[field] && localValues[field].trim();
    if (!inputValue) return;

    const existing = filters[field] || [];
    // Prevent duplicates.
    const newValue = existing.includes(inputValue) ? existing : [...existing, inputValue];

    const newFilters = { ...filters, [field]: newValue };
    onFilterChange(newFilters);

    // Clear the local input field.
    setLocalValues((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  // Apply checkbox filter
  const handleCheckboxSubmit = (field) => () => {
    if (localCheckboxes[field] === undefined) return;

    onFilterChange({
      ...filters,
      [field]: [localCheckboxes[field]],
    });
  };

  // Apply select filter - append to existing values rather than replacing
  const handleSelectSubmit = (field) => () => {
    const selectValue = localValues[field];
    if (!selectValue) return;

    const existing = filters[field] || [];
    // Prevent duplicates
    const newValue = existing.includes(selectValue) ? existing : [...existing, selectValue];

    onFilterChange({
      ...filters,
      [field]: newValue,
    });

    // Clear the local select value
    setLocalValues((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  // For main accordions: toggling a panel simply adds or removes its name.
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      if (isExpanded) {
        return [...prevExpanded, panel];
      }
      return prevExpanded.filter((p) => p !== panel);
    });
  };

  // For subaccordions: manage multiple open panels via an array.
  const handleChildAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedChildren((prev) => {
      if (isExpanded) {
        return [...prev, panel];
      }
      return prev.filter((p) => p !== panel);
    });
  };

  const toggleLocation = (nodeId) => {
    setExpandedLocations((prev) => ({
      ...prev,
      [nodeId]: !prev[nodeId],
    }));
  };

  const searchInNode = (node) => {
    if (!node) return false;
    const searchTerm = locationSearch.toLowerCase();
    if (node.name.toLowerCase().includes(searchTerm)) return true;
    if (node.areas && node.areas.some((area) => area.toLowerCase().includes(searchTerm))) {
      return true;
    }
    if (node.countries && node.countries.some((country) => searchInNode(country))) {
      return true;
    }
    if (node.regions && node.regions.some((region) => searchInNode(region))) {
      return true;
    }
    return false;
  };

  const expandMatchingNodes = (node, path = "") => {
    if (!node) return;
    const nodeId = `${path}${node.name}`;
    const matchesSearch = searchInNode(node);
    if (matchesSearch) {
      setExpandedLocations((prev) => ({
        ...prev,
        [nodeId]: true,
      }));
      if (path) {
        const parentPath = path.split("/").slice(0, -1).join("/");
        if (parentPath) {
          setExpandedLocations((prev) => ({
            ...prev,
            [parentPath]: true,
          }));
        }
      }
    }
    if (node.countries) {
      node.countries.forEach((country) => expandMatchingNodes(country, `${nodeId}/`));
    }
    if (node.regions) {
      node.regions.forEach((region) => expandMatchingNodes(region, `${nodeId}/`));
    }
  };

  const handleRegionSelect = (area, parent) => {
    setSelectedRegions((prev) => {
      let newRegions = [...prev];
      const fullLocation = parent && !area.includes(parent) ? `${area}, ${parent}` : area;
      if (newRegions.includes(fullLocation)) {
        newRegions = newRegions.filter((r) => r !== fullLocation);
      } else {
        newRegions.push(fullLocation);
      }
      onFilterChange({
        ...filters,
        geo: applyDistance(newRegions),
      });
      return newRegions;
    });
  };

  const onEnrichedSwitch = (newEnriched) => {
    setEnriched(newEnriched);
    if (newEnriched) {
      onFilterChange({ ...filters, enriched: true });
    } else {
      // eslint-disable-next-line no-shadow
      const { enriched, ...rest } = filters;
      onFilterChange(rest);
      if (Object.keys(rest).length === 0) {
        onFilterReset(!filterReset);
      }
    }
  };

  // Remove a filter and trigger search
  const handleRemoveFilter = (key) => {
    const { [key]: removed, ...newFilters } = filters;
    onFilterChange(newFilters);
    if (key === "enriched") {
      onEnrichedSwitch(false);
    }
  };

  // Remove a specific value from an array filter
  const handleRemoveArrayFilterValue = (key, value) => {
    if (Array.isArray(filters[key])) {
      const newValues = filters[key].filter((val) => val !== value);
      const newFilters = { ...filters };

      if (newValues.length === 0) {
        delete newFilters[key];
      } else {
        newFilters[key] = newValues;
      }

      onFilterChange(newFilters);
    }
    const count = Object.values(filters).reduce((acc, arr) => acc + arr.length, 0);
    if (count === 1) {
      onFilterReset(!filterReset);
      setEnriched(false);
    }
  };

  const filterLocationNode = (node, path = "", parent = null, indentLevel = 0) => {
    if (!node) return null;
    const nodeId = `${path}${node.name}`;
    const matchesSearch = searchInNode(node);
    if (locationSearch && !matchesSearch) return null;
    const isExpanded = expandedLocations[nodeId] || false;
    const areaWithParent = parent ? `${node.name}, ${parent}` : node.name;
    const isNodeSelected = selectedRegions.includes(areaWithParent);
    const handleClick = (event) => {
      event.stopPropagation();
      toggleLocation(nodeId);
    };

    return (
      <Box key={nodeId}>
        <ListItem
          button
          onClick={handleClick}
          sx={{
            py: 0.5,
            cursor: "pointer",
            pl: `${indentLevel * 2}rem`,
            "&:hover": { bgcolor: "action.hover" },
            ...(locationSearch &&
              node.name.toLowerCase().includes(locationSearch.toLowerCase()) && {
                bgcolor: "action.selected",
              }),
          }}
        >
          <IconButton size="small" sx={{ mr: 1 }} onClick={handleClick}>
            {isExpanded ? (
              <ExpandMoreIcon fontSize="small" />
            ) : (
              <ChevronRightIcon fontSize="small" />
            )}
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={isNodeSelected}
                onChange={() => handleRegionSelect(node.name, parent)}
                size="small"
              />
            }
            label={<Typography variant="body2">{node.name}</Typography>}
          />
        </ListItem>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {node.areas?.map((area) => (
              <ListItem key={`${nodeId}/${area}`} sx={{ pl: 4, py: 0.5 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRegions.includes(`${area}, ${node.name}`)}
                      onChange={() => handleRegionSelect(area, node.name)}
                      size="small"
                    />
                  }
                  label={<Typography variant="body2">{area}</Typography>}
                />
              </ListItem>
            ))}
            {node.countries?.map((country) =>
              filterLocationNode(country, `${nodeId}/`, node.name, indentLevel + 1)
            )}
            {node.regions?.map((region) =>
              filterLocationNode(region, `${nodeId}/`, node.name, indentLevel + 1)
            )}
          </List>
        </Collapse>
      </Box>
    );
  };

  const renderFilterContent = (category) => {
    switch (category.name || category) {
      case "Company Name":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter company name"
              value={localValues.name || ""}
              onChange={handleLocalChange("name")}
            />
            <Button variant="contained" onClick={handleFieldSubmit("name")}>
              Apply
            </Button>
          </Box>
        );
      case "Domain":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter domain"
              value={localValues.domain || ""}
              onChange={handleLocalChange("domain")}
            />
            <Button variant="contained" onClick={handleFieldSubmit("domain")}>
              Apply
            </Button>
          </Box>
        );
      case "Location":
        return (
          <Stack spacing={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <TextField
                fullWidth
                size="small"
                placeholder="Enter location"
                value={localValues.location || ""}
                onChange={handleLocalChange("location")}
              />
              <Button variant="contained" onClick={handleFieldSubmit("location")}>
                Apply
              </Button>
            </Box>
            <TextField
              fullWidth
              size="small"
              placeholder="Search locations..."
              value={locationSearch}
              onChange={(e) => {
                const newValue = e.target.value;
                setLocationSearch(newValue);
                if (!newValue) {
                  setExpandedLocations({});
                } else {
                  locationData.continents.forEach((continent) => {
                    expandMatchingNodes(continent);
                  });
                }
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1,
                },
              }}
            />
            <Box
              sx={{
                maxHeight: 300,
                overflowY: "auto",
                border: "1px solid #e0e0e0",
                borderRadius: 1,
                bgcolor: "background.paper",
              }}
            >
              <List component="nav" dense>
                {locationData.continents.map((continent) => filterLocationNode(continent))}
              </List>
            </Box>
            <Box sx={{ mt: 2, width: "100%" }}>
              <Slider
                value={distance}
                onChange={handleSliderChange}
                aria-labelledby="distance-slider"
                valueLabelDisplay="auto"
                step={10}
                min={0}
                max={160}
              />
            </Box>
          </Stack>
        );
      case "Founded":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <FormControl fullWidth size="small">
              <Select
                value={localValues.founded || ""}
                onChange={handleLocalChange("founded")}
                displayEmpty
              >
                <MenuItem value="">All Years</MenuItem>
                <MenuItem value="2020+">2020+</MenuItem>
                <MenuItem value="2015-2019">2015-2019</MenuItem>
                <MenuItem value="2010-2014">2010-2014</MenuItem>
                <MenuItem value="2000-2009">2000-2009</MenuItem>
                <MenuItem value="pre-2000">Before 2000</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleSelectSubmit("founded")}>
              Apply
            </Button>
          </Box>
        );
      case "Technologies":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter technologies"
              value={localValues.techstack || ""}
              onChange={handleLocalChange("techstack")}
            />
            <Button variant="contained" onClick={handleFieldSubmit("techstack")}>
              Apply
            </Button>
          </Box>
        );
      case "Competitors":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter competitors"
              value={localValues.competitors || ""}
              onChange={handleLocalChange("competitors")}
            />
            <Button variant="contained" onClick={handleFieldSubmit("competitors")}>
              Apply
            </Button>
          </Box>
        );
      case "Industry":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <FormControl fullWidth size="small">
              <Select
                value={localValues.industry || ""}
                onChange={handleLocalChange("industry")}
                displayEmpty
              >
                <MenuItem value="">All Industries</MenuItem>
                <MenuItem value="technology">Technology</MenuItem>
                <MenuItem value="healthcare">Healthcare</MenuItem>
                <MenuItem value="finance">Finance & Insurance</MenuItem>
                <MenuItem value="retail">Retail & Consumer</MenuItem>
                <MenuItem value="manufacturing">Manufacturing</MenuItem>
                <MenuItem value="education">Education</MenuItem>
                <MenuItem value="media">Media & Entertainment</MenuItem>
                <MenuItem value="real_estate">Real Estate</MenuItem>
                <MenuItem value="energy">Energy & Utilities</MenuItem>
                <MenuItem value="transportation">Transportation & Logistics</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleSelectSubmit("industry")}>
              Apply
            </Button>
          </Box>
        );
      case "Company Size":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <FormControl fullWidth size="small">
              <Select
                value={localValues.employees || ""}
                onChange={handleLocalChange("employees")}
                displayEmpty
              >
                <MenuItem value="">Any Size</MenuItem>
                <MenuItem value="1-10">1-10 employees</MenuItem>
                <MenuItem value="11-50">11-50 employees</MenuItem>
                <MenuItem value="51-200">51-200 employees</MenuItem>
                <MenuItem value="201-500">201-500 employees</MenuItem>
                <MenuItem value="1001-5000">1001-5000 employees</MenuItem>
                <MenuItem value="5001<">5001+ employees</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleSelectSubmit("employees")}>
              Apply
            </Button>
          </Box>
        );
      case "Revenue":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <FormControl fullWidth size="small">
              <Select
                value={localValues.revenue || ""}
                onChange={handleLocalChange("revenue")}
                displayEmpty
              >
                <MenuItem value="">Any Revenue</MenuItem>
                <MenuItem value="0-1000000">$0 - $1M</MenuItem>
                <MenuItem value="1000000-10000000">$1M - $10M</MenuItem>
                <MenuItem value="10000000-50000000">$10M - $50M</MenuItem>
                <MenuItem value="50000000-100000000">$50M - $100M</MenuItem>
                <MenuItem value="500000000-1000000000">$100M - $500M</MenuItem>
                <MenuItem value="5000000000-10000000000">$500M - $1B</MenuItem>
                <MenuItem value="10000000000-50000000000">$1B - $5B</MenuItem>
                <MenuItem value="5000000000<">$5B+</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleSelectSubmit("revenue")}>
              Apply
            </Button>
          </Box>
        );
      default:
        if (category.children) {
          return (
            <Stack spacing={1}>
              {category.children.map((child) => {
                if (typeof child === "string") {
                  const fieldName = child.toLowerCase().replace(/ & /g, "_").replace(/ /g, "_");
                  return (
                    <Box
                      key={child}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={localCheckboxes[fieldName] || false}
                            onChange={handleLocalCheckboxChange(fieldName)}
                          />
                        }
                        label={child}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleCheckboxSubmit(fieldName)}
                      >
                        Apply
                      </Button>
                    </Box>
                  );
                }
                return (
                  <Accordion
                    key={child.name}
                    // Check if this subaccordion is expanded by seeing if its name is in expandedChildren
                    expanded={!child.locked && expandedChildren.includes(child.name)}
                    onChange={handleChildAccordionChange(child.name)}
                    disabled={child.locked}
                    sx={{
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxShadow: "none",
                      "&.Mui-disabled": {
                        backgroundColor: "rgba(0, 0, 0, 0.03)",
                      },
                      "&:before": {
                        display: "none",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={child.hasChevron && <ExpandMoreIcon />}
                      sx={{
                        "& .MuiAccordionSummary-content": {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        },
                      }}
                    >
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography>{child.name}</Typography>
                      </Box>
                      {child.locked && (
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <LockIcon fontSize="small" />
                        </IconButton>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>{renderFilterContent(child)}</AccordionDetails>
                  </Accordion>
                );
              })}
            </Stack>
          );
        }
        return null;
    }
  };

  return (
    <Grid item xs={12} md={3}>
      <Card sx={{ p: 3 }}>
        {/* Header with Filters title, Clear All, and the new toggle */}
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
          <Typography variant="button" gutterBottom>
            Filters
          </Typography>
          <Typography
            variant="button"
            gutterBottom
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              onFilterReset(!filterReset);
              setEnriched(false);
            }}
          >
            Clear All
          </Typography>
        </Box>
        {/* Toggle for Enriched Search */}
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={enriched}
                onChange={(e) => onEnrichedSwitch(e.target.checked)}
                color="primary"
              />
            }
            label="Enriched"
          />
        </Box>

        {/* Active Filters Section */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            {Object.entries(filters).length > 0 && "Active Filters:"}
          </Typography>
          {Object.entries(filters).map(([key, value]) => {
            if (!value || (Array.isArray(value) && value.length === 0)) return null;

            // If the value is an array, create separate chips for each value
            if (Array.isArray(value)) {
              return value.map((item, index) => (
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${key}-${index}`}
                  label={`${key}: ${item}`}
                  onDelete={() => handleRemoveArrayFilterValue(key, item)}
                  sx={{ mr: 1, mb: 1 }}
                />
              ));
            }

            // For non-array values, display a single chip
            return (
              <Chip
                key={key}
                label={`${key}: ${value}`}
                onDelete={() => handleRemoveFilter(key)}
                sx={{ mr: 1, mb: 1 }}
              />
            );
          })}
        </Box>

        <Divider sx={{ mb: 3 }} />
        <Stack spacing={1}>
          {companyFilterCategories.map((category) => (
            <Accordion
              key={category.name}
              expanded={!category.locked && expanded.includes(category.name)}
              onChange={handleAccordionChange(category.name)}
              disabled={category.locked}
              sx={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                boxShadow: "none",
                "&.Mui-disabled": { backgroundColor: "rgba(0, 0, 0, 0.03)" },
                "&:before": { display: "none" },
              }}
            >
              <AccordionSummary
                expandIcon={category.hasChevron && <ExpandMoreIcon />}
                sx={{
                  "& .MuiAccordionSummary-content": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  },
                }}
              >
                <Typography>{category.name}</Typography>
                {category.locked && (
                  <IconButton size="small" sx={{ ml: 1 }}>
                    <LockIcon fontSize="small" />
                  </IconButton>
                )}
              </AccordionSummary>
              <AccordionDetails>{renderFilterContent(category)}</AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      </Card>
    </Grid>
  );
};

CompanyFilterSidebar.propTypes = {
  filters: PropTypes.object.isRequired,
  filterReset: PropTypes.bool.isRequired,
  onFilterReset: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default CompanyFilterSidebar;
