import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Button,
  Box,
  Avatar,
  Grid,
  Checkbox,
  Divider,
  Link,
} from "@mui/material";
import PropTypes from "prop-types";
import { Language, Business, People, AttachMoney, CalendarToday, Phone } from "@mui/icons-material";
import CompanytEnrichedProfileCard from "../detail/Enriched/Enriched";
import TickerSymbolIcon from "../../../icons/ticker_symbol";
import { formatNumber, renderSocialIcons } from "../../helper";

const websiteUrl = (emailDomain) => {
  if (emailDomain) {
    return emailDomain.includes("http") ? emailDomain : `https://${emailDomain}`;
  }
  return null;
};

const Enriched = ({ data, id, onFindContacts }) => {
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    if (data.id === id) {
      setViewMore(true);
    }
  }, [id]);

  return (
    <Card
      sx={{
        p: 3,
        border: "1px solid #e0e0e0",
        borderRadius: 2,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        position: "relative",
        overflow: "hidden",
        transition: "box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out",
        "&:hover": {
          borderColor: "rgba(255, 77, 0, 0.85)",
          // boxShadow: "0px 8px 4px rgba(255, 77, 0, 0.3)",
        },
        "&::before": {
          content: '""',
          position: "absolute",
          width: "220%", // Keep the width as before or adjust based on your preference
          height: "150px",
          backgroundColor: "rgba(206, 206, 206, 0.14)",
          transform: "rotate(-10deg)",
          top: "10px",
          right: "10px",
        },
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={1.8} sx={{ display: "flex", alignItems: "flex-start" }}>
          <Checkbox id={data.id} key={data.id} size="small" sx={{ mr: 1 }} />
          <Avatar
            src={data.logo_url}
            alt={data.name}
            variant="rounded"
            sx={{ width: 56, height: 56 }}
          />
        </Grid>
        <Grid item xs={12} sm={10.2}>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Typography variant="h6" fontWeight={500} color="primary">
                {data.name}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {data.phone && (
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Phone sx={{ mr: 1, color: "rgba(255, 77, 0, 0.85)" }} />
                    <Typography variant="body2">
                      <strong>{data.phone}</strong>
                    </Typography>
                  </Box>
                )}
              </Typography>

              <Box sx={{ display: "flex", mt: 1, gap: 0.5 }}>{renderSocialIcons(data.links)}</Box>
            </Grid>

            <Grid item xs={12} sm={4} sx={{ textAlign: { sm: "right" } }}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Language fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />
                <Typography variant="body2" color="primary">
                  {data.original_response.website_domain && (
                    <Link href={websiteUrl(data.original_response.website_domain)} target="_new">
                      {data.original_response.website_domain}
                    </Link>
                  )}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                {data.original_response.address.description}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", gap: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Business fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />
                <Typography variant="body2">{data.industry || "-"}</Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <People fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />
                <Typography variant="body2">{data.num_employees}</Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AttachMoney fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />
                <Typography variant="body2">
                  {data.revenue ? formatNumber(data.revenue) : " - "}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TickerSymbolIcon
                  symbol={data.original_response.ticker_symbol}
                  fontSize="small"
                  sx={{ color: "text.secondary", mr: 1 }}
                />
                <Typography variant="body2">
                  {data.original_response.ticker_symbol || " - "}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CalendarToday fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />
                <Typography variant="body2">{data.year_founded || " - "}</Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              {data.hasContacts ? (
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "rgba(58, 123, 213, 0.1)",
                    borderColor: "transparent",
                    color: "rgba(255, 77, 0, 0.85)",
                    "&:hover": {
                      backgroundColor: "rgba(58, 123, 213, 0.2)",
                      borderColor: "transparent",
                    },
                  }}
                >
                  View Contacts
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={() => onFindContacts(data.id)}>
                  Find Contacts
                </Button>
              )}

              <Button
                sx={{
                  color: "rgba(255, 77, 0, 0.85)",
                  p: 0,
                  minWidth: "auto",
                  textTransform: "none",
                  boxShadow: "none",
                }}
                onClick={() => setViewMore(!viewMore)}
              >
                View More
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid sx={{ display: viewMore ? "block" : "none" }}>
        {data.is_enriched && <CompanytEnrichedProfileCard data={data} />}
      </Grid>
    </Card>
  );
};

Enriched.defaultProps = {
  id: null,
};

Enriched.propTypes = {
  id: PropTypes.number,
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  onFindContacts: PropTypes.func.isRequired,
};

export default Enriched;
