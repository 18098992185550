import React, { useState } from "react";
import PropTypes from "prop-types";
import Enriched from "./main/Enriched";
import NonEnriched from "./main/NonEnriched";

const ProspectCard = ({ data, onEnrichDetails }) => {
  const [id, setId] = useState(null);
  const handleEnrichDetails = (pid) => {
    onEnrichDetails(pid);
    setId(pid);
  };

  if (data.is_enriched) {
    return <Enriched data={data} id={id} />;
  }
  return <NonEnriched data={data} onEnrichDetails={handleEnrichDetails} />;
};

ProspectCard.propTypes = {
  data: PropTypes.object.isRequired,
  onEnrichDetails: async () => {},
};

ProspectCard.defaultProps = {
  onEnrichDetails: async () => {},
};

export default ProspectCard;
