import { Card, Typography } from "@mui/material";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

function NoRecordFound() {
  return (
    <Card
      sx={{
        p: 4,
        textAlign: "center",
        backgroundColor: "rgba(58, 123, 213, 0.05)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <SentimentDissatisfiedIcon sx={{ fontSize: 48, color: "primary.main" }} />

      <Typography variant="h6" color="primary" fontWeight={500}>
        No Records Found
      </Typography>

      <Typography variant="body2" color="text.secondary">
        We couldn’t find any results. Try adjusting your filters or search again.
      </Typography>
    </Card>
  );
}

export default NoRecordFound;
