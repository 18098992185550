import { useHistory, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Swal from "sweetalert2";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Grid, Stack } from "@mui/material";
import { useSoftUIController, setMiniSidenav } from "context";
import HomeIcon from "layouts/crm_dashboard/icons/HomeIcon";
// import ProfileIcon from "layouts/crm_dashboard/icons/ProfileIcon";
// import ChatIcon from "layouts/crm_dashboard/icons/ChatIcon";
import LogoutIcon from "layouts/crm_dashboard/icons/LogoutIcon";
import DocumentsIcon from "layouts/crm_dashboard/icons/DocumentsIcon";
import TeleroidLogo from "assets/images/iconComponents/LetterLogo";
import ArrowOpenIcon from "layouts/crm_dashboard/icons/ArrowOpenIcon";
import ArrowCloseIcon from "layouts/crm_dashboard/icons/ArrowCloseIcon";
import CompanyIcon from "layouts/crm_dashboard/icons/CompanyIcon";
import FileManagerIcon from "layouts/crm_dashboard/icons/FileManagerIcon";
import AppointmentIcon from "layouts/crm_dashboard/icons/AppointmentIcon";
import AnalyticsIcon from "layouts/crm_dashboard/icons/AnalyticsIcon";
import OicLeadIcon from "layouts/crm_dashboard/icons/OicLeadIcon";
import Badge from "@mui/material/Badge";
import { CustomerUserContext } from "App";
import AppointmentAnalyticsIcon from "layouts/crm_dashboard/icons/AppointmentAnalyticsIcon";
import OicAnalyticsIcon from "layouts/crm_dashboard/icons/OicAnalyticsIcon";
import FollowUpIcon from "layouts/crm_dashboard/icons/FollowUpIcon";
import CampaignsIcon from "layouts/crm_dashboard/icons/CampaignsIcon";
import teleroidsArm from "../../assets/images/authentication_v2/teleroidsArm.png";

function Sidenav({ routes, totalNewNotes }) {
  const [controller, dispatch] = useSoftUIController();
  const [customerUser] = useContext(CustomerUserContext);
  const currentUrl = window.location.href;
  const splitPath = currentUrl.split("/");
  const [expandedKey, setExpandedKey] = useState(null);
  const path = splitPath[splitPath.length - 1];
  const location = useLocation();
  const history = useHistory();
  const kpiActivated = customerUser?.customer?.kpi_activated;
  const p360Active = customerUser?.customer?.p360_subscription_level !== "deactivated";

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    window.addEventListener("resize", handleMiniSidenav);

    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const handleDrawerOpen = () => {
    setMiniSidenav(dispatch, false);
  };

  const handleDrawerClose = () => {
    setMiniSidenav(dispatch, true);
  };

  function getIconByKey(key) {
    switch (key) {
      case "dashboards":
        return <HomeIcon color={path === "" ? "#FF4D00" : "#5F5F5F"} />;
      case "appointments":
        return <AppointmentIcon color={key === path ? "#FF4D00" : "#5F5F5F"} />;
      case "oic":
        return <OicLeadIcon color={key === path ? "#FF4D00" : "#5F5F5F"} />;
      case "prospect360":
        return <AnalyticsIcon color={key === path ? "#FF4D00" : "#5F5F5F"} />;
      // case "profile":
      //   return <ProfileIcon color={key === path ? "#FF4D00" : "#5F5F5F"} />;
      case "chat":
        return (
          <>
            <Badge badgeContent={totalNewNotes} color="secondary">
              {/* <ChatIcon color={key === path ? "#FF4D00" : "#5F5F5F"} /> */}
            </Badge>
          </>
        );
      case "kpis":
        return <AnalyticsIcon color={key === path ? "#FF4D00" : "#5F5F5F"} />;
      case "campaigns":
        return <CampaignsIcon color={key === path ? "#FF4D00" : "#5F5F5F"} size={35} />;
      case "appointment-analytics":
        return <AppointmentAnalyticsIcon color={key === path ? "#FF4D00" : "#5F5F5F"} />;
      case "oic-analytics":
        return <OicAnalyticsIcon color={key === path ? "#FF4D00" : "#5F5F5F"} />;
      case "roi":
        return <AppointmentAnalyticsIcon color={key === path ? "#FF4D00" : "#5F5F5F"} />;
      case "follow-up":
        return <FollowUpIcon color={key === path ? "#FF4D00" : "#5F5F5F"} />;
      case "documents":
        return <DocumentsIcon color={key === path ? "#FF4D00" : "#5F5F5F"} />;
      case "company-profile":
        return <CompanyIcon color={key === path ? "#FF4D00" : "#5F5F5F"} />;
      case "file":
        return <FileManagerIcon color={key === path ? "#FF4D00" : "#5F5F5F"} />;

      default:
        return null;
    }
  }

  const handleListItemClick = (route) => {
    history.push(route);
    setExpandedKey(null);
  };

  const handleLogout = () => {
    axios
      .post(`/api/logout`)
      .then((res) => {
        if (res.status === 202) {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("auth_user");
          localStorage.removeItem("selectedCustomerId");
          localStorage.removeItem("companyId");
          localStorage.removeItem("employeeDetail");
          localStorage.removeItem("customerUser");
          window.location = "/";
        }
      })
      .catch((errorData) => {
        Swal.fire({
          title: "Error!",
          text: errorData.message,
          icon: "error",
        }).then(() => {
          window.location = "/";
        });
      });
  };
  const toggleExpand = (key) => {
    setExpandedKey((prevState) => (prevState === key ? null : key));
  };

  const renderNestedRoutes = (collapseRoutes) =>
    collapseRoutes.map(({ name, key, route }) => (
      <ListItem
        sx={{ marginBottom: "10px", marginLeft: "10px" }}
        key={key}
        disablePadding
        onClick={() => handleListItemClick(route)}
      >
        <ListItemButton
          sx={{
            "& .MuiListItemIcon-root": { minWidth: "1px", marginRight: "12px" },
          }}
        >
          <ListItemIcon>{getIconByKey(key)}</ListItemIcon>
          {controller.miniSidenav === false && (
            <ListItemText
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  color: key === path ? "#FF4D00" : "#5F5F5F",
                },
              }}
              primary={name}
            />
          )}
        </ListItemButton>
      </ListItem>
    ));

  useEffect(() => {
    if (
      (path === "appointment-analytics" ||
        path === "oic-analytics" ||
        path === "kpis" ||
        path === "campaigns" ||
        path === "prospect360" ||
        path === "follow-up") &&
      !kpiActivated &&
      !p360Active
    ) {
      history.push("/dashboard");
    }
  }, []);

  useEffect(() => {
    const currentRoute = routes.find((route) =>
      route.collapse?.some((nested) => nested.route === location.pathname)
    );
    if (currentRoute) {
      setExpandedKey(currentRoute.key);
    } else {
      setExpandedKey(null);
    }
  }, [location.pathname, routes]);

  return (
    <>
      <Grid container sx={{ marginRight: "60px" }}>
        <Drawer
          className="drawer"
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: controller.miniSidenav === false ? "270px" : "80px",
              boxSizing: "border-box",
              padding: controller.miniSidenav === false ? "10px" : "2px",
              margin: "0",
              borderRadius: "0",
              backgroundColor: "#E7E7E74F",
              height: "100vh",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Stack direction="column" justifyContent="space-between" height="100%">
            <Stack>
              <List
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <img width="60px" src={teleroidsArm} alt="ArmLogo" />
                {controller.miniSidenav === true ? null : (
                  <>
                    <TeleroidLogo width="130" />
                    <ListItemIcon onClick={handleDrawerClose} sx={{ marginLeft: "10px" }}>
                      <ArrowCloseIcon />
                    </ListItemIcon>
                  </>
                )}
              </List>

              {controller.miniSidenav === false ? null : (
                <List>
                  <hr
                    style={{
                      width: "85%",
                      margin: "20px auto",
                      marginBottom: "1px",
                      marginTop: "1px",
                      border: "0.5px solid #5F5F5F33",
                    }}
                  />
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        marginTop: "10px",
                        marginLeft: "7px",
                      }}
                      onClick={handleDrawerOpen}
                    >
                      <ArrowOpenIcon />
                    </ListItemIcon>
                  </ListItemButton>
                </List>
              )}

              <List sx={{ marginLeft: controller.miniSidenav === false ? "10px" : "3px" }}>
                <hr
                  style={{
                    width: "85%",
                    margin: "20px auto",
                    marginTop: "10px",
                    marginBottom: "35px",
                    border: "0.5px solid #5F5F5F33",
                  }}
                />
                {routes.map(({ name, key, route, collapse }) =>
                  getIconByKey(key) &&
                  key !== "vr-info" &&
                  key !== "title-pages" &&
                  key !== "chat" &&
                  key !== "enrichment" &&
                  !(key === "prospect360" && !p360Active) ? (
                    <div key={key}>
                      {(key !== "kpis" && key !== "follow-up") ||
                      (key === "kpis" && kpiActivated) ||
                      (key === "follow-up" && kpiActivated) ? (
                        <ListItem
                          sx={{ marginBottom: "10px" }}
                          disablePadding
                          onClick={() =>
                            collapse ? toggleExpand(key) : handleListItemClick(route)
                          }
                        >
                          <ListItemButton
                            sx={{
                              "& .MuiListItemIcon-root": { minWidth: "1px", marginRight: "12px" },
                            }}
                          >
                            <ListItemIcon>{getIconByKey(key)}</ListItemIcon>
                            {controller.miniSidenav === false ? (
                              <ListItemText
                                sx={{
                                  "& .MuiTypography-root": {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    color:
                                      key === path ||
                                      (key === "dashboards" && path === "") ||
                                      (key === "campaigns" && path === "campaigns") ||
                                      (key === "appointment" && path === "") ||
                                      (key === "analytics" && path === "appointment-analytics") ||
                                      (key === "analytics" && path === "oic-analytics") ||
                                      (key === "prospect360" && path === "prospect360") ||
                                      (key === "kpis" && path === "kpis")
                                        ? "#FF4D00"
                                        : "#5F5F5F",
                                  },
                                }}
                                primary={name}
                              />
                            ) : null}
                          </ListItemButton>
                        </ListItem>
                      ) : null}
                      {kpiActivated && collapse && collapse.length > 0 && expandedKey === key && (
                        <List disablePadding>{renderNestedRoutes(collapse)}</List>
                      )}
                    </div>
                  ) : null
                )}
              </List>
            </Stack>
            <List
              sx={{
                marginTop: "20px",
                marginBottom: "10px",
                marginLeft: "10px",
              }}
            >
              <Stack direction="column">
                <hr
                  style={{
                    width: "85%", // Change this value to the desired width
                    margin: "20px auto",
                    marginBottom: "15px",
                    border: "0.5px solid #5F5F5F33",
                  }}
                />
                <ListItem
                  sx={{ marginBottom: "10px" }}
                  key="logout"
                  disablePadding
                  onClick={handleLogout}
                >
                  <ListItemButton
                    sx={{ "& .MuiListItemIcon-root": { minWidth: "1px", marginRight: "12px" } }}
                  >
                    <ListItemIcon>
                      <LogoutIcon color="#5F5F5F" />
                    </ListItemIcon>
                    {controller.miniSidenav === false ? (
                      <ListItemText
                        sx={{
                          "& .MuiTypography-root": {
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          },
                        }}
                        primary="Log out"
                      />
                    ) : null}
                  </ListItemButton>
                </ListItem>
              </Stack>
            </List>
          </Stack>
        </Drawer>
      </Grid>
    </>
  );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalNewNotes: PropTypes.number.isRequired,
};

export default Sidenav;
