import React, { useState } from "react";
import {
  Card,
  Typography,
  Button,
  Avatar,
  Box,
  Grid,
  Checkbox,
  Link,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import { CalendarToday, Email } from "@mui/icons-material";
import ProspectNonEnrichedProfileCard from "../detail/NonEnriched";
import { renderEmailSection, renderPhoneSection, renderSocialIcons } from "../../helper";

const NonEnriched = ({ data, onEnrichDetails }) => {
  const [viewMore, setViewMore] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = async (id) => {
    setLoading(true);
    await onEnrichDetails(id); // Assuming this is an async function
  };

  return (
    <Card
      sx={{
        p: 3,
        border: "1px solid #e0e0e0",
        borderRadius: 2,
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        position: "relative",
        overflow: "hidden",
        transition: "box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out",
        "&::before": {
          content: '""',
          position: "absolute",
          width: "220%", // Keep the width as before or adjust based on your preference
          height: "150px",
          backgroundColor: "rgba(197, 197, 197, 0.3)",
          transform: "rotate(-10deg)",
          top: "10px",
          right: "10px",
        },
      }}
    >
      <Grid container>
        <Grid item sx={{ width: "30px", display: "flex", alignItems: "flex-start", mr: 2 }}>
          <Checkbox size="small" sx={{ mr: 2 }} />
        </Grid>

        <Grid item xs>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", direction: "row" }}>
                <Avatar
                  src={data.profile_pic}
                  alt={data.name}
                  sx={{ width: 56, height: 56, mr: 2 }}
                />
                <Box>
                  <Typography xs={12} variant="h6" fontWeight={500}>
                    {data.name}
                  </Typography>
                  <Typography xs={12} variant="body2" color="text.secondary">
                    {data.current_title}
                  </Typography>
                  {data.birth_year && (
                    <Box xs={12} sx={{ display: "flex", mt: 1, alignItems: "center" }}>
                      <CalendarToday fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />
                      <Typography variant="body2">Birth Year: {data.birth_year}</Typography>
                    </Box>
                  )}
                  <Box sx={{ display: "flex", mt: 1, gap: 0.5 }}>
                    {renderSocialIcons({ ...data.links, linkedin: data.linkedin_url })}
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ textAlign: { sm: "right" } }}>
              <Typography variant="subtitle1" color="primary" fontWeight={500}>
                <Link
                  sx={{ color: "rgba(255, 77, 0, 0.85)" }}
                  href={data.current_employer_website || `https://${data.current_employer_domain}`}
                  target="_new"
                >
                  {data.current_employer}
                </Link>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {data.location}
              </Typography>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
            <Box>
              {data.teaser &&
                data.teaser.professional_emails &&
                renderEmailSection("B", data.teaser.professional_emails)}
              {data.teaser && data.teaser.phones && renderPhoneSection(data.teaser.phones)}
              {data.teaser &&
                data.teaser.personal_emails &&
                renderEmailSection("P", data.teaser.personal_emails)}
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 2 }}
            >
              {data.hasEmail ? (
                <Button
                  variant="outlined"
                  startIcon={<Email />}
                  sx={{
                    backgroundColor: "rgba(58, 123, 213, 0.1)",
                    borderColor: "transparent",
                    color: "rgba(255, 77, 0, 0.85)",
                    "&:hover": {
                      backgroundColor: "rgba(58, 123, 213, 0.2)",
                      borderColor: "transparent",
                    },
                  }}
                >
                  Send Email
                </Button>
              ) : (
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  onClick={() => handleClick(data.id)}
                >
                  {loading ? (
                    <CircularProgress size={20} sx={{ color: "rgba(255, 77, 0, 0.85)" }} />
                  ) : (
                    "Get More Info"
                  )}
                </Button>
              )}
              <Button
                sx={{
                  color: "rgba(255, 77, 0, 0.85)",
                  minWidth: "auto",
                  textTransform: "none",
                  boxShadow: "none",
                  alignSelf: "center",
                }}
                onClick={() => setViewMore(!viewMore)}
              >
                View More
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid sx={{ display: viewMore ? "block" : "none" }}>
        {!data.is_enriched && <ProspectNonEnrichedProfileCard data={data} />}
      </Grid>
    </Card>
  );
};

NonEnriched.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  onEnrichDetails: PropTypes.objectOf(PropTypes.func).isRequired,
};

export default NonEnriched;
