import { Avatar, Box, Button, Chip, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Business, Email, Phone } from "@mui/icons-material";
import { getSocialBgColor, getSocialIcon } from "./SocialIcons";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;

export const renderEmailSection = (type, emails) => {
  if (!emails || emails.length === 0) return null;

  const firstEmail = emails[0];

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
      {type === "B" && <Business fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />}
      {type === "P" && <Email fontSize="small" sx={{ color: "text.secondary", mr: 1 }} />}
      <Typography variant="body2">{firstEmail}</Typography>
      {emails.length > 1 && (
        <Chip
          label={`+${emails.length - 1} emails`}
          size="small"
          sx={{
            ml: 1,
            height: "20px",
            backgroundColor: "rgba(58, 123, 213, 0.1)",
            color: "rgba(255, 77, 0, 0.85)",
          }}
        />
      )}
    </Box>
  );
};

export const renderPhoneSection = (phones) => {
  if (!phones || phones.length === 0) return null;

  let firstPhone = phones.filter((p) => p.is_premium)[0];
  firstPhone = firstPhone ?? phones[0];

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
      <Phone fontSize="small" sx={{ color: "text.secondary", mr: 0 }} />
      <Button
        size="small"
        sx={{
          ml: 1,
          color: "rgba(255, 77, 0, 0.85)",
          p: 0,
          minWidth: "auto",
          textTransform: "none",
          boxShadow: "none",
        }}
      >
        {firstPhone.number || firstPhone}
      </Button>
      {phones.length > 1 && (
        <Chip
          label={`+${phones.length - 1} phones`}
          size="small"
          sx={{
            ml: 1,
            height: "20px",
            backgroundColor: "rgba(58, 123, 213, 0.1)",
            color: "rgba(255, 77, 0, 0.85)",
          }}
        />
      )}
    </Box>
  );
};

export const renderSocialIcons = (links) =>
  Object.keys(links).length > 0 &&
  Object.keys(links).map(
    (platform) =>
      links[platform] && (
        <Link href={links[platform]} target="_new">
          <Avatar
            key={`social-${links[platform]}`}
            sx={{
              width: 28,
              height: 28,
              bgcolor: getSocialBgColor(platform),
            }}
          >
            {getSocialIcon(platform)}
          </Avatar>
        </Link>
      )
  );

export const formatNumber = (num) => {
  if (num >= 1e12) return `${(num / 1e12).toFixed(1).replace(/\.0$/, "")}T`;
  if (num >= 1e9) return `${(num / 1e9).toFixed(1).replace(/\.0$/, "")}B`;
  if (num >= 1e6) return `${(num / 1e6).toFixed(1).replace(/\.0$/, "")}M`;
  if (num >= 1e3) return `${(num / 1e3).toFixed(1).replace(/\.0$/, "")}k`;
  return num.toString();
};
