/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/Navbar";
import Footer from "examples/Footer";
import PropTypes from "prop-types";
// import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsBarChart from "examples/Charts/BarCharts_v2/ReportChart";
// Data
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SuiVectorMapRoot from "components/SuiVectorMap/SuiVectorMapRoot";
import axios from "axios";
import { saveAs } from "file-saver";
import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Icon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import CampaignTypes from "common/constants/campaign-types";
import { dateToYMD } from "common/helpers";
import Swal from "sweetalert2";
import { CustomerUserContext } from "App";
import OicTable from "components/authentication_v2/OicTable";
import OicCampaigns from "components/authentication_v2/OicCampaigns";
import { makeStyles } from "@material-ui/core/styles";
import MultiSelect from "components/MultiSelect";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import OICModal from "../appointments/components/OICModal";

const OicLeads = () => {
  const history = useHistory();
  const [customerUser] = useContext(CustomerUserContext);
  const [today, setToday] = useState(new Date());
  const [campaigns, setCampaigns] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [oicLeadsVectorMap, setOICLeadsVectorMap] = useState({});
  const [oicLeadsDataTableValues, setOICLeadsDataTableValues] = useState({});
  const [campaign, setCampaign] = useState();
  const [campaignId, setCampaignId] = useState(0);
  // const [infoAppts, setInfoAppts] = useState([]);
  // const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [loadingCampaignItems, setLoadingCampaignItems] = useState(true);
  const [openOICModal, setOpenOICModal] = useState(false);
  const [selectedOicLead, setSelectedOicLead] = useState(null);
  const [campaignValue, setCampaignValue] = useState([]);
  // const [kanbanData, setKanbanData] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const handleOpenOICModal = (pOICLeadId) => {
    history.push({
      pathname: "/enrichment-oic",
      state: {
        appointmentIdParam: pOICLeadId,
        customerUser: { customerUser },
        campaignId: { campaignId },
        appointmentId: { selectedOicLead },
      },
    });
    // setSelectedOicLead(pOICLeadId);
    // setOpenOICModal(true);
  };
  const handleCloseOICModal = () => {
    setSelectedOicLead(null);
    setOpenOICModal(false);
  };

  const [resset, setResset] = useState({
    cols: null,
    res: null,
  });

  const fetchRes = (cols, res) => {
    setOICLeadsVectorMap([]);
    // setKanbanData(null);

    const rSet = res?.data?.data;
    const dat = rSet;

    const dataVal = {
      columns: cols,

      rows: dat,
    };

    setOICLeadsDataTableValues(dataVal);
    setTimeout(() => {
      setOICLeadsVectorMap(rSet);
    }, 400);

    // setKanbanData(rSet);
    setLoadingCampaignItems(false);
  };

  useEffect(() => {
    if (resset.cols && resset.res) {
      fetchRes(resset.cols, resset.res);
    }
    return () => {};
  }, []);

  const options = [
    // eslint-disable-next-line no-shadow
    ...campaignData.map((campaign) => ({
      value: campaign.id,
      label: campaign.name,
    })),
  ];

  const displayValue = campaignData
    .filter((c) => campaignValue.includes(c.id))
    .map((c) => ({
      value: c.id,
      label: c.name,
    }));

  const handleCampaignValueChange = (e) => {
    if (e.some((item) => item.label === "All")) {
      setCampaignValue(campaignData.map((item) => item.id).concat([0]));
    } else if (e.length === 0) {
      setCampaignValue([]);
    } else {
      setCampaignValue(e.map((item) => item.value));
    }
  };

  const fetchCampaignAppointmentsOrOics = (pCampaignId = 0) => {
    const campId = campaignValue.length;
    axios
      .get(`/api/customers/${customerUser.customer.id}/campaigns/${pCampaignId}/oic/fields`)
      .then((r) => {
        const cols = r.data.data;
        const oicUrl =
          campId === 0
            ? `/api/customers/${customerUser.customer.id}/campaigns/0/oic`
            : `/api/customers/${customerUser.customer.id}/campaigns/0/oic?campaign_ids=${campaignValue}`;
        return axios.get(oicUrl).then((res) => {
          setResset({
            cols,
            res,
          });
          fetchRes(cols, res);
        });
      })
      .catch(() => {
        fetchRes([], null);
        setOICLeadsDataTableValues([]);
        setOICLeadsVectorMap([]);
      });
  };

  const fetchCampaigns = () => {
    // setLoadingCampaigns(true);
    setLoadingCampaignItems(true);
    axios
      .get(`/api/customers/${customerUser.customer.id}/campaigns/oic`)
      .then((res) => {
        // setInfoAppts([
        //   {
        //     icon: { color: "primary", component: "library_books" },
        //     label: "All Leads",
        //     progress: { content: res.data.info.all_leads, percentage: 60 },
        //   },
        //   {
        //     icon: { color: "info", component: "touch_app" },
        //     label: "Imported Leads",
        //     progress: { content: res.data.info.num_of_order_request_leads, percentage: 60 },
        //   },
        //   // {
        //   //   icon: { color: "info", component: "touch_app" },
        //   //   label: "New Appointments",
        //   //   progress: { content: res.data.info.new_appointments, percentage: 90 },
        //   // },
        //   {
        //     icon: { color: "warning", component: "payment" },
        //     label: "new Messages",
        //     progress: { content: res.data.info.new_messages, percentage: 30 },
        //   },
        //   {
        //     icon: { color: "error", component: "extension" },
        //     label: "Active Campaigns",
        //     progress: { content: res.data.info.active_campaigns, percentage: 50 },
        //   },
        // ]);
        const campData = res.data.oicLeadCampaigns.data;
        setCampaignData(campData);
        if (history.location.state?.campaignId) {
          setCampaignValue(
            campData
              .filter((c) => c.id === history.location.state?.campaignId)
              .map((c) => c.id)
              .concat([0])
          );
        } else {
          setCampaignValue(campData.map((c) => c.id).concat([0]));
        }
        setCampaigns(res.data);
        // setLoadingCampaigns(false);
      })
      .catch(() => {
        setCampaigns([]);
        // setLoadingCampaigns(false);
      });
  };

  const queryParams = new URLSearchParams(window.location.search);

  const oicLeadIdParam = queryParams.get("oicLeadId");

  useEffect(() => {
    if (oicLeadIdParam !== undefined && oicLeadIdParam) {
      handleOpenOICModal(oicLeadIdParam);
    }

    return () => {};
  }, [oicLeadIdParam]);

  useEffect(() => {
    setToday(dateToYMD(new Date()));

    return () => {};
  }, [today]);

  useEffect(() => {
    fetchCampaigns();
    return () => {};
  }, [null, customerUser]);

  useEffect(() => {
    fetchCampaignAppointmentsOrOics(campaignId);
    return () => {};
  }, [campaignId, customerUser, campaignValue]);

  const [isSelected, setIsSelected] = useState("#E7E7E7");

  const handleAllCampaignsClick = () => {
    setCampaign(null);
    setCampaignId(0);
    setIsSelected("#E7E7E7");
  };

  const handleCampaignClick = (e, pCampaign) => {
    e.persist();

    setCampaign(pCampaign);
    setCampaignId(pCampaign.id);
    setIsSelected(pCampaign.id);
  };

  const [searchCampaigns, setSearchCampaigns] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isFavourite, setIsFavourite] = useState(0);
  const [buttonClickFavourite, setButtonClickFavourite] = useState(false);

  const handleFavourite = () => {
    setIsFavourite((prev) => (prev === 0 ? 1 : 0));
    setButtonClickFavourite(!buttonClickFavourite);
  };

  const handleFavoriteClick = (event) => {
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/oic/${event.id}/update-favorite-status`
      )
      .then(() => {
        fetchCampaignAppointmentsOrOics(campaignId);
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  const handleCampaignSearch = (e) => {
    const inputValue = e.target.value;
    setSearchCampaigns(inputValue);

    const filteredItems = campaigns.oicLeadCampaigns.data.filter((item) =>
      item.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    setFilteredData(filteredItems);
  };

  useEffect(() => {
    setFilteredData(campaigns?.oicLeadCampaigns?.data);
  }, [campaigns]);

  const handleEventClick = (event) => {
    if (event.classNames.includes("event-info")) {
      Swal.fire({
        title: "OICLead Confirmation",
        text: "Do you want to confirm the oicLead?",
        icon: "info",
        showCancelButton: true,
        // confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#d33",
        confirmButtonText: "Yes, confirm it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const context = {
            is_confirmation_form: "yes",
            review_status: "accepted",
          };
          axios
            .post(
              `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/oic/${event.id}/update-review-status`,
              context
            )
            .then(() => {
              // Swal.fire("Success!", r.message, "success");
              handleOpenOICModal(event.id);
              fetchCampaignAppointmentsOrOics(campaignId);
            })
            .catch((err) => {
              Swal.fire("Error!", err.message, "error");
            });
        }
      });
    } else {
      handleOpenOICModal(event.id);
    }
  };

  const [search, setSearch] = useState("");
  const searchLower = search.toLowerCase();

  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const [selectedItems, setSelectedItems] = useState([]);

  const handleExport = (e) => {
    e.preventDefault();
    const payload = {
      oic_lead_ids: selectedItems,
    };
    axios
      .post(`/api/customers/${customerUser.customer.id}/campaigns/0/oic/export`, payload, {
        responseType: "blob",
      })
      .then((res) => {
        saveAs(res.data, "oic-leads-export.csv");
        Swal.fire("Success!", res.data.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openAction = Boolean(anchorEl);
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  function CustomTabPanel(props) {
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <Box sx={{ p: 3, pt: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const [buttonClick, setButtonClick] = useState(false);
  const [campaignsList, setCampaignsList] = useState(false);
  // const [value, setValue] = useState(0);
  // const [style, setStyle] = useState({
  //   border: "0 !important",
  // });

  const tableWidth =
    oicLeadsDataTableValues?.rows !== undefined && oicLeadsDataTableValues?.rows?.length;

  const useStyles = makeStyles(() => ({
    datePicker: {
      width: "250px",
      "@media (max-width: 380px)": {
        width: "180px",
      },
    },
    tableInfo: {
      width: "100%",
      float: "left",
    },
    map: {
      width: "100%",
      float: "right",
      border: "1px solid white",
      paddingTop: "15px",
      overflow: "auto",
    },
    "@media (min-width: 1120px)": {
      map: {
        width: "30%",
      },
      tableInfo: {
        width: tableWidth !== false && tableWidth !== 0 ? "70%" : "100%",
      },
    },
    tabs: {
      minHeight: "0 !important",
      "@media (max-width: 670px)": {
        marginBottom: "10px",
      },
    },
    "@media (max-width: 800px)": {
      filters: {
        display: "block",
        flexDirection: "column",
        alignItems: "center",
      },
      filterTab: {
        display: "block",
        flexDirection: "column",
        alignItems: "center",
      },
      favButton: {
        marginTop: "10px",
      },
    },
    tabButton: {
      backgroundColor: buttonClick ? "#FF4D00D9" : "#E7E7E7",
      color: buttonClick ? "white" : "#FF4D00D9",
      padding: "5px 35px",
      border: "0",
      borderRadius: "6px",
      cursor: "pointer",
      marginRight: "1%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "@media (max-width: 799px)": {
        marginBottom: "10px",
      },
    },
    favButton: {
      backgroundColor: buttonClickFavourite ? "#FF4D00D9" : "#E7E7E7",
      color: buttonClickFavourite ? "white" : "#FF4D00D9",
      padding: "5px 35px",
      border: "0",
      borderRadius: "6px",
      cursor: "pointer",
      marginRight: "1%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "@media (max-width: 670px)": {
        marginBottom: "10px",
      },
    },
  }));

  const classes = useStyles();

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  //   setStyle({
  //     border: "0 !important",
  //   });
  // };
  CustomTabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //   };
  // }
  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDEDED",
            color: "#FF4D00D9",
            fontFamily: "Poppins",
            borderRadius: "6px",
            textTransform: "none",
            marginRight: "-5%",
            fontSize: "13px",
            fontWeight: "600",
            width: "15%",
            paddingLeft: "5%",
            padding: "5% 7% !important",
            minHeight: "0px !important",
            "&.Mui-selected": {
              backgroundColor: "#FF4D00D9",
              color: "#fff",
              fontFamily: "Poppins",
              borderRadius: "6px",
              marginRight: "-5%",
              zIndex: "1",
              fontSize: "13px",
              fontWeight: "600",
              width: "5%",
              paddingRight: "5%",
            },
          },
        },
      },
    },
  });

  const handleCampaigns = () => {
    setCampaignsList(!campaignsList);
    setButtonClick(!buttonClick);
  };

  const loader2 = (
    <Stack
      alignItems="center"
      className="loader"
      display="flex"
      justifyContent="center"
      height="40vh"
      width="100%"
    >
      <CircularProgress color="warning" /> Loading..
    </Stack>
  );

  const renderCampaignItems = () => {
    if (loadingCampaignItems) {
      return loader2;
    }
    if (campaign) {
      return (
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Grid item xs={12} lg={12}>
              <SuiBox my={3} position="relative">
                <ThemeProvider theme={theme}>
                  <Card sx={{ borderRadius: "5px", boxShadow: "none", padding: "24px" }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Stack
                        className={classes.filters}
                        width="100%"
                        direction="row"
                        alignItems="center"
                      >
                        {/* <Tabs className={classes.tabs} value={value} onChange={handleChange}>
                          <Tab style={value === 0 ? style : {}} label="Table" {...a11yProps(0)} />
                          <Tab style={value === 1 ? style : {}} label="Kanban" {...a11yProps(1)} />
                        </Tabs> */}
                        <button
                          className={classes.tabButton}
                          type="button"
                          onClick={handleCampaigns}
                        >
                          <Icon sx={{ color: buttonClick ? "white" : "#FF4D00D9" }}>
                            filter_list
                          </Icon>
                          Campaigns
                        </button>
                        <button
                          className={classes.favButton}
                          type="button"
                          onClick={handleFavourite}
                        >
                          <Icon sx={{ color: buttonClickFavourite ? "white" : "#FF4D00D9" }}>
                            filter_list
                          </Icon>
                          Favorite
                        </button>
                      </Stack>
                      <Stack width="20%" sx={{ width: { sx: "100%" } }}>
                        {campaignsList && (
                          <Stack
                            sx={{
                              backgroundColor: "#F7F7F7",
                              borderRadius: "5px",
                              paddingTop: "4%",
                              paddingBottom: "4%",
                              paddingLeft: "1%",
                              width: "90%",
                            }}
                            mb="2.8%"
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Icon sx={{ color: "#FF4D00D9" }}>search</Icon>
                            <input
                              style={{
                                border: "0",
                                background: "transparent",
                                outline: "none",
                              }}
                              type="text"
                              value={searchCampaigns}
                              placeholder="Search..."
                              onChange={handleCampaignSearch}
                            />
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  </Card>
                </ThemeProvider>
              </SuiBox>
              {campaignsList && (
                <OicCampaigns
                  isSelected={isSelected}
                  filteredData={filteredData}
                  buttonClick={buttonClick}
                  campaigns={campaigns}
                  handleCampaignClick={handleCampaignClick}
                  handleAllCampaignsClick={handleAllCampaignsClick}
                />
              )}
              <Card sx={{ borderRadius: "5px", boxShadow: "none" }}>
                <CustomTabPanel>
                  <OicTable
                    table={oicLeadsDataTableValues}
                    canSearch
                    openAppointment={handleEventClick}
                    isFavourite={isFavourite}
                  />
                </CustomTabPanel>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Grid item xs={12} lg={12}>
            <SuiBox position="relative">
              <ThemeProvider theme={theme}>
                <Card
                  sx={{
                    borderRadius: "5px",
                    boxShadow: "none",
                    padding: "24px",
                    paddingBottom: "0",
                    overflow: "visible",
                  }}
                >
                  <div>
                    <div>
                      <Grid
                        container
                        padding={2}
                        // justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                        direction={{ xs: "column", md: "row" }}
                      >
                        <Stack
                          direction="row"
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            paddingTop: "0.5%",
                            paddingBottom: "0.5%",
                            paddingLeft: "0.5%",
                            border: isFocused ? "1px solid #a1a1aa" : "1px solid #e4e4e7",
                            "&:hover": {
                              border: "1px solid #a1a1aa",
                            },
                          }}
                          alignItems="center"
                        >
                          <Icon sx={{ color: "#71717a", marginRight: "3px" }}>search</Icon>
                          <input
                            style={{
                              border: "0",
                              background: "white",
                              outline: "none",
                            }}
                            type="text"
                            value={search}
                            placeholder="Search OIC Lead"
                            onChange={onSearchChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                          />
                        </Stack>
                        <div className={classes.filters}>
                          <MultiSelect
                            key={campaignValue.length}
                            className={classes.datePicker}
                            placeholder="Campaign"
                            isMulti
                            options={options}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            value={displayValue}
                            onChange={handleCampaignValueChange}
                            allowSelectAll={false}
                          />
                        </div>
                        <div>
                          <Button
                            // variant="contained"
                            style={{
                              padding: "10px",
                              border: "1px solid #e4e4e7",
                              backgroundColor: buttonClickFavourite ? "rgb(235 235 235)" : "white",
                              color: buttonClickFavourite ? "#18181b" : "#8591a4",
                              marginLeft: "10px",
                              fontSize: "13px",
                            }}
                            onClick={handleFavourite}
                          >
                            Favourite
                          </Button>
                        </div>
                        <>
                          <div>
                            <Button
                              style={{
                                color: "#8591a4",
                                padding: "10px",
                                border: "1px solid #e4e4e7",
                                fontSize: "13px",
                              }}
                              onClick={handleActionClick}
                            >
                              Action
                              <ArrowDropDownIcon />
                            </Button>
                            <Menu
                              id="long-menu"
                              anchorEl={anchorEl}
                              open={openAction}
                              onClose={() => handleActionClose()}
                              PaperProps={{
                                style: {
                                  width: "25ch",
                                },
                              }}
                            >
                              <MenuItem value="export" onClick={handleExport}>
                                Export
                              </MenuItem>
                            </Menu>
                          </div>
                        </>
                      </Grid>
                    </div>
                    <div className={classes.tableInfo}>
                      {campaignsList && (
                        <OicCampaigns
                          isSelected={isSelected}
                          filteredData={filteredData}
                          buttonClick={buttonClick}
                          campaigns={campaigns}
                          handleCampaignClick={handleCampaignClick}
                          handleAllCampaignsClick={handleAllCampaignsClick}
                        />
                      )}
                    </div>
                  </div>
                  <Stack
                    className={classes.filterTab}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack
                      className={classes.filters}
                      width="100%"
                      direction="row"
                      alignItems="center"
                    >
                      {/* <Tabs className={classes.tabs} value={value} onChange={handleChange}>
                        <Tab style={value === 0 ? style : {}} label="Table" {...a11yProps(0)} />
                        <Tab style={value === 1 ? style : {}} label="Kanban" {...a11yProps(1)} />
                      </Tabs> */}
                      {/* <Grid className={classes.filters} mr={{ md: 1, lg: 1, sm: 0, xs: 0 }}> */}
                      {/* <MultiSelect
                          className={classes.datePicker}
                          placeholder="Campaign"
                          isMulti
                          options={options}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          value={displayValue}
                          onChange={handleCampaignValueChange}
                          allowSelectAll
                        />
                      </Grid>
                      <button className={classes.favButton} type="button" onClick={handleFavourite}>
                        <Icon sx={{ color: buttonClickFavourite ? "white" : "#FF4D00D9" }}>
                          filter_list
                        </Icon>
                        Favorite
                      </button> */}
                    </Stack>
                    <Stack width="20%" sx={{ width: { sx: "100%" } }}>
                      {campaignsList && (
                        <Stack
                          sx={{
                            backgroundColor: "#F7F7F7",
                            borderRadius: "5px",
                            paddingTop: "4%",
                            paddingBottom: "4%",
                            paddingLeft: "1%",
                            width: "90%",
                          }}
                          mb="2.8%"
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <Icon sx={{ color: "#FF4D00D9" }}>search</Icon>
                          <input
                            style={{
                              border: "0",
                              background: "transparent",
                              outline: "none",
                            }}
                            type="text"
                            value={searchCampaigns}
                            placeholder="Search..."
                            onChange={handleCampaignSearch}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Card>
              </ThemeProvider>
            </SuiBox>
            <Grid item md={12} ml={4.7}>
              {campaignsList && (
                <OicCampaigns
                  isSelected={isSelected}
                  filteredData={filteredData}
                  buttonClick={buttonClick}
                  campaigns={campaigns}
                  handleCampaignClick={handleCampaignClick}
                  handleAllCampaignsClick={handleAllCampaignsClick}
                />
              )}
            </Grid>
            <Card
              sx={{ borderRadius: "5px", boxShadow: "none", padding: "24px", paddingTop: "10px" }}
            >
              <div>
                <div className={classes.tableInfo}>
                  <CustomTabPanel>
                    <OicTable
                      table={oicLeadsDataTableValues}
                      canSearch
                      handleFavoriteClick={handleFavoriteClick}
                      openAppointment={handleEventClick}
                      isFavourite={isFavourite}
                      searchLower={searchLower}
                      setSelectedItems={setSelectedItems}
                      selectedItems={selectedItems}
                    />
                  </CustomTabPanel>
                </div>
                <div className={classes.map}>
                  <Grid item xs={12} lg={12}>
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        lg={12}
                        xl={12}
                        sx={{ width: "100%", height: "100%", padding: "2px 2px 4px 2px" }}
                      >
                        <SuiVectorMapRoot
                          appointmentsOrOICLeads={oicLeadsVectorMap ?? []}
                          fetchCampaignAppointmentsOrOics={fetchCampaignAppointmentsOrOics}
                          openAppointmetModal={handleEventClick}
                          campaignType={CampaignTypes.OIC}
                          campaignId={campaignId}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>

              {/* <CustomTabPanel value={value} index={1}>
                {kanbanData && kanbanData.length > 0 ? (
                  <OicKanban
                    data={kanbanData}
                    canSearch
                    openAppointment={handleEventClick}
                    handleFavoriteClick={handleFavoriteClick}
                    isFavourite={isFavourite}
                    customerUser={customerUser}
                    fetchCampaignAppointmentsOrOics={fetchCampaignAppointmentsOrOics}
                  />
                ) : null}
              </CustomTabPanel> */}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar componentName="Oic" />
      {openOICModal && selectedOicLead ? (
        <OICModal
          campaignId={campaignId}
          oicLeadId={selectedOicLead}
          open={openOICModal}
          handleClose={handleCloseOICModal}
          customerUser={customerUser}
        />
      ) : (
        ""
      )}
      <SuiBox py={3}>
        <Grid container spacing={3} sx={{ zIndex: 100 }}>
          <Grid item xs={12} lg={12} mb={3}>
            {/* <Grid container>
              <Grid item xs={12} md={12}>
                {loadingCampaignItems ? (
                  loader2
                ) : (
                  <ReportsBarChart title="" description="" chart={{}} items={infoAppts} />
                )}
              </Grid>
            </Grid> */}
            {renderCampaignItems()}
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
};

export default OicLeads;
