const peopleFilterCategories = [
  { name: "Name", hasChevron: true },
  { name: "Current Employer", hasChevron: true },
  { name: "Current Title", hasChevron: true },
  { name: "Location", hasChevron: true },
  {
    name: "Contact Info",
    hasChevron: true,
    children: [
      { name: "Email", hasChevron: true },
      { name: "Phone", hasChevron: true },
      { name: "Social Links", hasChevron: true },
    ],
  },
  {
    name: "Experience",
    hasChevron: true,
    children: [
      { name: "Past Employers", hasChevron: true },
      { name: "Past Titles", hasChevron: true },
      { name: "Years of Experience", hasChevron: true },
    ],
  },
  {
    name: "Company Details",
    hasChevron: true,
    children: [
      { name: "Industry", hasChevron: true },
      { name: "Company Size", hasChevron: true },
      { name: "Revenue", hasChevron: true },
    ],
  },
  { name: "Education", hasChevron: true },
];

export default peopleFilterCategories;
