import { LinkedIn, Twitter, Facebook, GitHub, Code } from "@mui/icons-material";

// Social media icon mapping
export const getSocialIcon = (platform) => {
  switch (platform) {
    case "linkedin":
      return <LinkedIn fontSize="small" />;
    case "twitter":
      return <Twitter fontSize="small" />;
    case "facebook":
      return <Facebook fontSize="small" />;
    case "github":
      return <GitHub fontSize="small" />;
    case "stackoverflow":
      return <Code fontSize="small" />;
    default:
      return null;
  }
};

// Social media background color mapping
export const getSocialBgColor = (platform) => {
  switch (platform) {
    case "linkedin":
      return "#0077B5";
    case "twitter":
      return "#1DA1F2";
    case "facebook":
      return "#4267B2";
    case "github":
      return "#333333";
    case "stackoverflow":
      return "#F48024";
    default:
      return "#CCCCCC";
  }
};
