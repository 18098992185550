const companyFilterCategories = [
  {
    name: "General",
    hasChevron: true,
    children: [
      { name: "Company Name", hasChevron: true },
      { name: "Domain", hasChevron: true },
      { name: "Location", hasChevron: true },
      // { name: "Intent", hasChevron: true /* , locked: true */ },
      { name: "Founded", hasChevron: true },
      { name: "Technologies", hasChevron: true },
      { name: "Competitors", hasChevron: true },
    ],
  },
  { name: "Industry", hasChevron: true },
  {
    name: "Metrics",
    hasChevron: true,
    children: [
      { name: "Company Size", hasChevron: true },
      { name: "Revenue", hasChevron: true },
    ],
  },
  {
    name: "News",
    hasChevron: true,
    children: ["Funding", "Merges & Acquisitions", "Executive Changes"],
  },
];

export default companyFilterCategories;
