"use client";

import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  FormControl,
  TextField,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Select,
  MenuItem,
  IconButton,
  Button,
  Grid,
  Card,
  Collapse,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  Slider,
  Switch,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import PropTypes from "prop-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import peopleFilterCategories from "./peopleFilterCategories";
import locationData from "../companies/locationData";

const ProspectFilterSidebar = ({
  filters,
  onFilterChange,
  filterReset,
  onFilterReset,
  // Optionally pass a search trigger function; here we simply assume onFilterChange will trigger search.
  // onSearch = () => {},
}) => {
  // Manage main and subaccordions with arrays.
  const [expanded, setExpanded] = useState([]);
  const [expandedChildren, setExpandedChildren] = useState([]);
  const [expandedLocations, setExpandedLocations] = useState({});
  const [locationSearch, setLocationSearch] = useState("");
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [distance, setDistance] = useState(0);
  const [enriched, setEnriched] = useState(false);

  // Local state for input field values.
  const [localValues, setLocalValues] = useState({});

  // When filters change externally, clear local input fields.
  useEffect(() => {
    setLocalValues({});
  }, [filters]);

  const aDistance = (item) => {
    if (distance > 0) {
      return `${item.split("::")[0]}::~${distance}km`;
    }
    return item.split("::")[0];
  };

  const applyDistance = (geo) => geo.map((item) => aDistance(item));

  const handleSliderChange = (event, newValue) => {
    setDistance(newValue);
    if (filters.geo) {
      onFilterChange({
        ...filters,
        geo: applyDistance(filters.geo),
      });
    }
    if (filters.location) {
      onFilterChange({
        ...filters,
        location: applyDistance(filters.location),
      });
    }
  };

  // Update local state on field changes.
  const handleLocalChange = (field) => (event) => {
    const newValue = event.target.value;
    setLocalValues((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  // Append the field value to the filters array and clear the field.
  const handleFieldSubmit = (field) => () => {
    const inputValue = localValues[field] && localValues[field].trim();
    if (!inputValue) return;

    const existing = filters[field] || [];
    // Prevent duplicates.
    const newValue = existing.includes(inputValue) ? existing : [...existing, inputValue];

    const newFilters = { ...filters, [field]: newValue };
    onFilterChange(newFilters);
    // Optionally trigger search immediately.
    // onSearch(newFilters);

    // Clear the local input field.
    setLocalValues((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded((prev) => (isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)));
  };

  const handleChildAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedChildren((prev) =>
      isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
    );
  };

  const toggleLocation = (nodeId) => {
    setExpandedLocations((prev) => ({
      ...prev,
      [nodeId]: !prev[nodeId],
    }));
  };

  const searchInNode = (node) => {
    if (!node) return false;
    const searchTerm = locationSearch.toLowerCase();
    if (node.name.toLowerCase().includes(searchTerm)) return true;
    if (node.areas && node.areas.some((area) => area.toLowerCase().includes(searchTerm)))
      return true;
    if (node.countries && node.countries.some((country) => searchInNode(country))) return true;
    if (node.regions && node.regions.some((region) => searchInNode(region))) return true;
    return false;
  };

  const expandMatchingNodes = (node, path = "") => {
    if (!node) return;
    const nodeId = `${path}${node.name}`;
    const matchesSearch = searchInNode(node);
    if (matchesSearch) {
      setExpandedLocations((prev) => ({
        ...prev,
        [nodeId]: true,
      }));
      if (path) {
        const parentPath = path.split("/").slice(0, -1).join("/");
        if (parentPath) {
          setExpandedLocations((prev) => ({
            ...prev,
            [parentPath]: true,
          }));
        }
      }
    }
    if (node.countries) {
      node.countries.forEach((country) => expandMatchingNodes(country, `${nodeId}/`));
    }
    if (node.regions) {
      node.regions.forEach((region) => expandMatchingNodes(region, `${nodeId}/`));
    }
  };

  const handleRegionSelect = (area, parent) => {
    setSelectedRegions((prev) => {
      let newRegions = [...prev];
      const fullLocation = parent && !area.includes(parent) ? `${area}, ${parent}` : area;
      if (newRegions.includes(fullLocation)) {
        newRegions = newRegions.filter((r) => r !== fullLocation);
      } else {
        newRegions.push(fullLocation);
      }
      const newFilters = { ...filters, geo: applyDistance(newRegions) };
      onFilterChange(newFilters);
      // onSearch(newFilters);
      return newRegions;
    });
  };

  const filterLocationNode = (node, path = "", parent = null, indentLevel = 0) => {
    if (!node) return null;
    const nodeId = `${path}${node.name}`;
    const matchesSearch = searchInNode(node);
    if (locationSearch && !matchesSearch) return null;
    const isExpanded = expandedLocations[nodeId] || false;
    const areaWithParent = parent ? `${node.name}, ${parent}` : node.name;
    const isNodeSelected = selectedRegions.includes(areaWithParent);
    const handleClick = (event) => {
      event.stopPropagation();
      toggleLocation(nodeId);
    };

    return (
      <Box key={nodeId}>
        <ListItem
          button
          onClick={handleClick}
          sx={{
            py: 0.5,
            cursor: "pointer",
            pl: `${indentLevel * 2}rem`,
            "&:hover": { bgcolor: "action.hover" },
            ...(locationSearch &&
              node.name.toLowerCase().includes(locationSearch.toLowerCase()) && {
                bgcolor: "action.selected",
              }),
          }}
        >
          <IconButton size="small" sx={{ mr: 1 }} onClick={handleClick}>
            {isExpanded ? (
              <ExpandMoreIcon fontSize="small" />
            ) : (
              <ChevronRightIcon fontSize="small" />
            )}
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={isNodeSelected}
                onChange={() => handleRegionSelect(node.name, parent)}
                size="small"
              />
            }
            label={<Typography variant="body2">{node.name}</Typography>}
          />
        </ListItem>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {node.areas?.map((area) => (
              <ListItem key={`${nodeId}/${area}`} sx={{ pl: 4, py: 0.5 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRegions.includes(`${area}, ${node.name}`)}
                      onChange={() => handleRegionSelect(area, node.name)}
                      size="small"
                    />
                  }
                  label={<Typography variant="body2">{area}</Typography>}
                />
              </ListItem>
            ))}
            {node.countries?.map((country) =>
              filterLocationNode(country, `${nodeId}/`, node.name, indentLevel + 1)
            )}
            {node.regions?.map((region) =>
              filterLocationNode(region, `${nodeId}/`, node.name, indentLevel + 1)
            )}
          </List>
        </Collapse>
      </Box>
    );
  };

  const onEnrichedSwitch = (newEnriched) => {
    setEnriched(newEnriched);
    if (newEnriched) {
      onFilterChange({ ...filters, enriched: true });
    } else {
      // eslint-disable-next-line no-shadow
      const { enriched, ...rest } = filters;
      onFilterChange(rest);
      if (Object.keys(rest).length === 0) {
        onFilterReset(!filterReset);
      }
    }
  };

  // Remove a filter and trigger search.
  const handleRemoveFilter = (key) => {
    const { [key]: removed, ...newFilters } = filters;
    onFilterChange(newFilters);
    // Explicitly trigger search after removing filter
    // onSearch(newFilters);
    if (key === "enriched") {
      onEnrichedSwitch(false);
    }
  };

  // Remove a specific value from an array filter
  const handleRemoveArrayFilterValue = (key, value) => {
    if (Array.isArray(filters[key])) {
      const newValues = filters[key].filter((val) => val !== value);
      const newFilters = { ...filters };

      if (newValues.length === 0) {
        delete newFilters[key];
      } else {
        newFilters[key] = newValues;
      }

      onFilterChange(newFilters);
    }
    const count = Object.values(filters).reduce((acc, arr) => acc + arr.length, 0);
    if (count === 1) {
      onFilterReset(!filterReset);
      setEnriched(false);
    }
  };

  const renderFilterContent = (category) => {
    switch (category.name || category) {
      case "Name":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter name"
              value={localValues.name || ""}
              onChange={handleLocalChange("name")}
            />
            <Button variant="contained" onClick={handleFieldSubmit("name")}>
              Apply
            </Button>
          </Box>
        );
      case "Current Employer":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter current employer"
              value={localValues.current_employer || ""}
              onChange={handleLocalChange("current_employer")}
            />
            <Button variant="contained" onClick={handleFieldSubmit("current_employer")}>
              Apply
            </Button>
          </Box>
        );
      case "Current Title":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter current title"
              value={localValues.current_title || ""}
              onChange={handleLocalChange("current_title")}
            />
            <Button variant="contained" onClick={handleFieldSubmit("current_title")}>
              Apply
            </Button>
          </Box>
        );
      case "Location":
        return (
          <Stack spacing={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <TextField
                fullWidth
                size="small"
                placeholder="Enter location"
                value={localValues.location || ""}
                onChange={handleLocalChange("location")}
              />
              <Button variant="contained" onClick={handleFieldSubmit("location")}>
                Apply
              </Button>
            </Box>
            <TextField
              fullWidth
              size="small"
              placeholder="Search locations..."
              value={locationSearch}
              onChange={(e) => {
                const newValue = e.target.value;
                setLocationSearch(newValue);
                if (!newValue) {
                  setExpandedLocations({});
                } else {
                  locationData.continents.forEach((continent) => {
                    expandMatchingNodes(continent);
                  });
                }
              }}
              sx={{
                "& .MuiOutlinedInput-root": { borderRadius: 1 },
              }}
            />
            <Box
              sx={{
                maxHeight: 300,
                overflowY: "auto",
                border: "1px solid #e0e0e0",
                borderRadius: 1,
                bgcolor: "background.paper",
              }}
            >
              <List component="nav" dense>
                {locationData.continents.map((continent) => filterLocationNode(continent))}
              </List>
            </Box>
            <Box sx={{ mt: 2, width: "100%" }}>
              <Slider
                value={distance}
                onChange={handleSliderChange}
                aria-labelledby="distance-slider"
                valueLabelDisplay="auto"
                step={10}
                min={0}
                max={160}
              />
            </Box>
          </Stack>
        );
      case "Email":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter email"
              value={localValues.email || ""}
              onChange={handleLocalChange("email")}
            />
            <Button variant="contained" onClick={handleFieldSubmit("email")}>
              Apply
            </Button>
          </Box>
        );
      case "Phone":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter phone"
              value={localValues.phone || ""}
              onChange={handleLocalChange("phone")}
            />
            <Button variant="contained" onClick={handleFieldSubmit("phone")}>
              Apply
            </Button>
          </Box>
        );
      case "Social Links":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <FormControl fullWidth size="small">
              <Select
                value={localValues.social_links || ""}
                onChange={handleLocalChange("social_links")}
                displayEmpty
              >
                <MenuItem value="">Any Social Links</MenuItem>
                <MenuItem value="linkedin">LinkedIn</MenuItem>
                <MenuItem value="twitter">Twitter</MenuItem>
                <MenuItem value="facebook">Facebook</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleFieldSubmit("social_links")}>
              Apply
            </Button>
          </Box>
        );
      case "Past Employers":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter past employers"
              value={localValues.past_employers || ""}
              onChange={handleLocalChange("past_employers")}
            />
            <Button variant="contained" onClick={handleFieldSubmit("past_employers")}>
              Apply
            </Button>
          </Box>
        );
      case "Past Titles":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter past titles"
              value={localValues.past_titles || ""}
              onChange={handleLocalChange("past_titles")}
            />
            <Button variant="contained" onClick={handleFieldSubmit("past_titles")}>
              Apply
            </Button>
          </Box>
        );
      case "Years of Experience":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <FormControl fullWidth size="small">
              <Select
                value={localValues.years_experience || ""}
                onChange={handleLocalChange("years_experience")}
                displayEmpty
              >
                <MenuItem value="">Any Experience</MenuItem>
                <MenuItem value="0-2">0-2 years</MenuItem>
                <MenuItem value="3-5">3-5 years</MenuItem>
                <MenuItem value="6-10">6-10 years</MenuItem>
                <MenuItem value="10<">10+ years</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleFieldSubmit("years_experience")}>
              Apply
            </Button>
          </Box>
        );
      case "Industry":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <FormControl fullWidth size="small">
              <Select
                value={localValues.industry || ""}
                onChange={handleLocalChange("industry")}
                displayEmpty
              >
                <MenuItem value="">All Industries</MenuItem>
                <MenuItem value="technology">Technology</MenuItem>
                <MenuItem value="healthcare">Healthcare</MenuItem>
                <MenuItem value="finance">Finance</MenuItem>
                <MenuItem value="retail">Retail</MenuItem>
                <MenuItem value="manufacturing">Manufacturing</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleFieldSubmit("industry")}>
              Apply
            </Button>
          </Box>
        );
      case "Company Size":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <FormControl fullWidth size="small">
              <Select
                value={localValues.employees || ""}
                onChange={handleLocalChange("employees")}
                displayEmpty
              >
                <MenuItem value="">Any Size</MenuItem>
                <MenuItem value="1-10">1-10</MenuItem>
                <MenuItem value="11-50">11-50</MenuItem>
                <MenuItem value="51-200">51-200</MenuItem>
                <MenuItem value="201-500">201-500</MenuItem>
                <MenuItem value="501-1000">501-1000</MenuItem>
                <MenuItem value="1001-5000">1001-5000</MenuItem>
                <MenuItem value="5001<">5001+</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleFieldSubmit("employees")}>
              Apply
            </Button>
          </Box>
        );
      case "Revenue":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <FormControl fullWidth size="small">
              <Select
                value={localValues.revenue || ""}
                onChange={handleLocalChange("revenue")}
                displayEmpty
              >
                <MenuItem value="">Any Revenue</MenuItem>
                <MenuItem value="0-1000000">$0 - $1M</MenuItem>
                <MenuItem value="1000000-10000000">$1M - $10M</MenuItem>
                <MenuItem value="10000000-50000000">$10M - $50M</MenuItem>
                <MenuItem value="50000000-100000000">$50M - $100M</MenuItem>
                <MenuItem value="500000000-1000000000">$100M - $500M</MenuItem>
                <MenuItem value="5000000000-10000000000">$500M - $1B</MenuItem>
                <MenuItem value="10000000000-50000000000">$1B - $5B</MenuItem>
                <MenuItem value="5000000000<">$5B+</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleFieldSubmit("revenue")}>
              Apply
            </Button>
          </Box>
        );
      case "Education":
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter education details"
              value={localValues.education || ""}
              onChange={handleLocalChange("education")}
            />
            <Button variant="contained" onClick={handleFieldSubmit("education")}>
              Apply
            </Button>
          </Box>
        );
      default:
        if (category.children) {
          return (
            <Stack spacing={1}>
              {category.children.map((child) => (
                <Accordion
                  key={child.name || child}
                  expanded={!child.locked && expandedChildren.includes(child.name || child)}
                  onChange={handleChildAccordionChange(child.name || child)}
                  disabled={child.locked}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    boxShadow: "none",
                    "&.Mui-disabled": { backgroundColor: "rgba(0, 0, 0, 0.03)" },
                    "&:before": { display: "none" },
                  }}
                >
                  <AccordionSummary expandIcon={child.hasChevron && <ExpandMoreIcon />}>
                    <Typography>{child.name || child}</Typography>
                    {child.locked && (
                      <IconButton size="small" sx={{ ml: 1 }}>
                        <LockIcon fontSize="small" />
                      </IconButton>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>{renderFilterContent(child)}</AccordionDetails>
                </Accordion>
              ))}
            </Stack>
          );
        }
        return null;
    }
  };

  return (
    <Grid item xs={12} md={3}>
      <Card sx={{ p: 3 }}>
        {/* Header */}
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
          <Typography variant="button" gutterBottom>
            Filters
          </Typography>
          <Typography
            variant="button"
            gutterBottom
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              onFilterReset(!filterReset);
              setEnriched(false);
            }}
          >
            Clear All
          </Typography>
        </Box>
        {/* Toggle for Enriched Search */}
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={enriched}
                onChange={(e) => onEnrichedSwitch(e.target.checked)}
                color="primary"
              />
            }
            label="Enriched"
          />
        </Box>

        {/* Active Filters Section */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            {Object.entries(filters).length > 0 && "Active Filters:"}
          </Typography>
          {Object.entries(filters).map(([key, value]) => {
            if (!value || (Array.isArray(value) && value.length === 0)) return null;

            // If the value is an array, create separate chips for each value.
            if (Array.isArray(value)) {
              return value.map((item, index) => (
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${key}-${index}`}
                  label={`${key}: ${item}`}
                  onDelete={() => handleRemoveArrayFilterValue(key, item)}
                  sx={{ mr: 1, mb: 1 }}
                />
              ));
            }

            // For non-array values, display a single chip.
            return (
              <Chip
                key={key}
                label={`${key}: ${value}`}
                onDelete={() => handleRemoveFilter(key)}
                sx={{ mr: 1, mb: 1 }}
              />
            );
          })}
        </Box>

        <Divider sx={{ mb: 3 }} />

        <Stack spacing={1}>
          {peopleFilterCategories.map((category) => (
            <Accordion
              key={category.name}
              expanded={!category.locked && expanded.includes(category.name)}
              onChange={handleAccordionChange(category.name)}
              disabled={category.locked}
              sx={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                boxShadow: "none",
                "&.Mui-disabled": { backgroundColor: "rgba(0, 0, 0, 0.03)" },
                "&:before": { display: "none" },
              }}
            >
              <AccordionSummary
                expandIcon={category.hasChevron && <ExpandMoreIcon />}
                sx={{
                  "& .MuiAccordionSummary-content": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  },
                }}
              >
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography>{category.name}</Typography>
                  {category.badge && (
                    <Chip
                      label={category.badge}
                      size="small"
                      color="primary"
                      sx={{ height: 20, minWidth: 20 }}
                    />
                  )}
                </Box>
                {category.locked && (
                  <IconButton size="small" sx={{ ml: 1 }}>
                    <LockIcon fontSize="small" />
                  </IconButton>
                )}
              </AccordionSummary>
              <AccordionDetails>{renderFilterContent(category)}</AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      </Card>
    </Grid>
  );
};

ProspectFilterSidebar.propTypes = {
  filters: PropTypes.object.isRequired,
  filterReset: PropTypes.bool.isRequired,
  onFilterReset: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default ProspectFilterSidebar;
