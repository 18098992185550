/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Chip,
  Tab,
  Tabs,
} from "@mui/material";
import { LinkedIn, Language, Phone } from "@mui/icons-material";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
import CampaignList from "./LeadManager/CampaignList";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

const CompanyProfile = ({ data }) => {
  const company = data.original_response;
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Extracting company growth data
  const growthData =
    company.company_growth?.map((entry) => ({
      quarter: `${entry.year} Q${entry.quarter}`,
      ...entry.values,
    })) || [];

  // Extracting labels for X-axis
  const labels = growthData.map((entry) => entry.quarter);

  // Extracting department names dynamically
  const departments = new Set();
  growthData.forEach((entry) => {
    Object.keys(entry).forEach((key) => {
      if (key !== "quarter") departments.add(key);
    });
  });

  // Generate dataset for each department
  const colors = ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#FFC300"];
  const chartDatasets = [...departments].map((department, index) => ({
    label: department,
    data: growthData.map((entry) => entry[department]?.after || 0),
    borderColor: colors[index % colors.length],
    backgroundColor: colors[index % colors.length],
    fill: false,
    tension: 0.4,
  }));

  const chartData = {
    labels,
    datasets: chartDatasets,
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top" },
      tooltip: { mode: "index", intersect: false },
    },
    scales: {
      x: { title: { display: true, text: "Quarter" } },
      y: { title: { display: true, text: "Number of Employees" }, beginAtZero: true },
    },
  };

  return (
    <Grid container spacing={3} sx={{ p: 2 }}>
      {/* Header Section */}
      <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box>
            <Typography variant="h5" fontWeight="bold">
              {company.name || "Company Name Not Available"}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {company.industry || "Industry Not Specified"}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              {company.address?.description || "Location Not Available"}
            </Typography>
          </Box>
          <Box>
            {company.links?.linkedin && (
              <Tooltip title="LinkedIn" arrow>
                <IconButton href={company.links.linkedin} target="_blank" size="small">
                  <LinkedIn />
                </IconButton>
              </Tooltip>
            )}
            {company.website_domain && (
              <Tooltip title="Website" arrow>
                <IconButton href={`https://${company.website_domain}`} target="_blank" size="small">
                  <Language />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      {/* Description */}
      <Grid item container xs={12}>
        <Grid item container xs={5}>
          {/* Contact Information */}
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Contact Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {company.phone ? (
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Phone sx={{ mr: 1, color: "rgba(255, 77, 0, 0.85)" }} />
                    <Typography variant="body2">
                      <strong>{company.phone}</strong>
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No phone number available.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Revenue & Employees */}
          <Grid item xs={12} mb={1}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Company Size & Revenue
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Employees: {company.num_employees ? company.num_employees : "Not available"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Revenue: {company.revenue ? `€${company.revenue.toLocaleString()}` : "Not available"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} mb={1}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Funding Investors
            </Typography>
            <Box
              sx={{ display: "flex", gap: 1, flexWrap: "wrap", maxHeight: 200, overflowY: "auto" }}
            >
              {company.funding_investors && company.funding_investors.length > 0 ? (
                company.funding_investors.map((fundingInvestor, index) => (
                  <Chip key={index} label={fundingInvestor} color="primary" variant="outlined" />
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No funding investor data available.
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} mb={1}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Industry & NAICS Codes
            </Typography>
            <Box
              sx={{ display: "flex", gap: 1, flexWrap: "wrap", maxHeight: 200, overflowY: "auto" }}
            >
              {company.industries && company.industries.length > 0 ? (
                company.industries.map((industry, index) => (
                  <Chip key={index} label={industry} color="primary" variant="outlined" />
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No industry data available.
                </Typography>
              )}
            </Box>
            <Typography variant="body2" sx={{ mt: 1 }}>
              <strong>NAICS Codes:</strong>{" "}
              {company.naics_codes ? company.naics_codes.join(", ") : "Not available"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} mb={1}>
            <Typography variant="body2" sx={{ mt: 1 }}>
              <strong>SIC Codes:</strong>{" "}
              {company.sic_codes ? company.sic_codes.join(", ") : "Not available"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} mb={1}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Techstack
            </Typography>
            <Box
              sx={{ display: "flex", gap: 1, flexWrap: "wrap", maxHeight: 200, overflowY: "auto" }}
            >
              {company.techstack && company.techstack.length > 0 ? (
                company.techstack.map((techstackItem, index) => (
                  <Chip key={index} label={techstackItem} color="primary" variant="outlined" />
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No techstack data available.
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Competitors
            </Typography>
            {company.competitors && company.competitors.length > 0 ? (
              <List dense sx={{ maxHeight: 200, overflowY: "auto" }}>
                {company.competitors.map((competitor, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={competitor} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2" color="textSecondary">
                No competitors listed.
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={0.3} />
        <Grid item xs={12} sm={6.7}>
          <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
            <Tab label="Description" />
            <Tab label="Details" />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {tabValue === 0 && (
              <Typography variant="body2" color="text.secondary">
                {data.description ? (
                  data.description
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    minHeight="200px"
                  >
                    <Typography variant="body2" color="textSecondary" textAlign="center">
                      No detail data available.
                    </Typography>
                  </Box>
                )}
              </Typography>
            )}
            {tabValue === 1 && (
              <Typography variant="body2" color="text.secondary">
                {data.campaigns ? (
                  <CampaignList campaigns={data.campaigns} />
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    minHeight="200px"
                  >
                    <Typography variant="body2" color="textSecondary" textAlign="center">
                      No detail data available.
                    </Typography>
                  </Box>
                )}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Company Growth Over Time
        </Typography>
        {growthData.length > 0 ? (
          <Box sx={{ height: 350 }}>
            <Line data={chartData} options={chartOptions} />
          </Box>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No growth data available.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

CompanyProfile.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CompanyProfile;
