const locationData = {
  continents: [
    {
      name: "Africa",
      countries: [
        {
          name: "Algeria",
          regions: [
            {
              name: "Northern Algeria",
              areas: ["Algiers", "Oran", "Constantine", "Annaba"],
            },
            {
              name: "Southern Algeria",
              areas: ["Tamanrasset", "Ghardaia"],
            },
          ],
        },
        {
          name: "Egypt",
          regions: [
            {
              name: "Lower Egypt",
              areas: ["Cairo", "Alexandria", "Port Said"],
            },
            {
              name: "Upper Egypt",
              areas: ["Luxor", "Aswan"],
            },
          ],
        },
        {
          name: "South Africa",
          regions: [
            {
              name: "Gauteng",
              areas: ["Johannesburg", "Pretoria"],
            },
            {
              name: "Western Cape",
              areas: ["Cape Town"],
            },
            {
              name: "KwaZulu-Natal",
              areas: ["Durban"],
            },
          ],
        },
        {
          name: "Nigeria",
          regions: [
            {
              name: "Southwest Nigeria",
              areas: ["Lagos", "Ibadan"],
            },
            {
              name: "North Nigeria",
              areas: ["Kano", "Abuja"],
            },
            {
              name: "Southeast Nigeria",
              areas: ["Enugu", "Port Harcourt"],
            },
          ],
        },
        {
          name: "Kenya",
          regions: [
            {
              name: "Central Kenya",
              areas: ["Nairobi", "Thika"],
            },
            {
              name: "Coastal Kenya",
              areas: ["Mombasa", "Malindi"],
            },
          ],
        },
        {
          name: "Ethiopia",
          regions: [
            {
              name: "Addis Ababa Region",
              areas: ["Addis Ababa"],
            },
            {
              name: "Oromia Region",
              areas: ["Adama", "Dire Dawa"],
            },
          ],
        },
        {
          name: "Ghana",
          regions: [
            {
              name: "Greater Accra",
              areas: ["Accra", "Tema"],
            },
            {
              name: "Ashanti Region",
              areas: ["Kumasi"],
            },
          ],
        },
        {
          name: "Morocco",
          regions: [
            {
              name: "Northern Morocco",
              areas: ["Casablanca", "Rabat", "Tangier"],
            },
            {
              name: "Central Morocco",
              areas: ["Marrakech", "Fes"],
            },
          ],
        },
        {
          name: "Tunisia",
          regions: [
            {
              name: "Northern Tunisia",
              areas: ["Tunis", "Sfax"],
            },
          ],
        },
        {
          name: "Sudan",
          regions: [
            {
              name: "Khartoum State",
              areas: ["Khartoum", "Omdurman"],
            },
          ],
        },
        {
          name: "Uganda",
          regions: [
            {
              name: "Central Uganda",
              areas: ["Kampala"],
            },
          ],
        },
        {
          name: "Tanzania",
          regions: [
            {
              name: "Mainland Tanzania",
              areas: ["Dar es Salaam", "Dodoma"],
            },
            {
              name: "Zanzibar",
              areas: ["Zanzibar City"],
            },
          ],
        },
        {
          name: "Democratic Republic of the Congo",
          regions: [
            {
              name: "Western DRC",
              areas: ["Kinshasa"],
            },
            {
              name: "Eastern DRC",
              areas: ["Lubumbashi", "Goma"],
            },
          ],
        },
        {
          name: "Ivory Coast",
          regions: [
            {
              name: "Southern Ivory Coast",
              areas: ["Abidjan", "Yamoussoukro"],
            },
          ],
        },
        {
          name: "Senegal",
          regions: [
            {
              name: "Dakar Region",
              areas: ["Dakar"],
            },
          ],
        },
        {
          name: "Angola",
          regions: [
            {
              name: "Luanda Province",
              areas: ["Luanda"],
            },
          ],
        },
        {
          name: "Zambia",
          regions: [
            {
              name: "Lusaka Province",
              areas: ["Lusaka"],
            },
          ],
        },
        {
          name: "Zimbabwe",
          regions: [
            {
              name: "Harare Province",
              areas: ["Harare"],
            },
          ],
        },
        {
          name: "Botswana",
          regions: [
            {
              name: "South-East Botswana",
              areas: ["Gaborone"],
            },
          ],
        },
        {
          name: "Mozambique",
          regions: [
            {
              name: "Southern Mozambique",
              areas: ["Maputo"],
            },
          ],
        },
        {
          name: "Namibia",
          regions: [
            {
              name: "Central Namibia",
              areas: ["Windhoek"],
            },
          ],
        },
        {
          name: "Rwanda",
          regions: [
            {
              name: "Kigali Region",
              areas: ["Kigali"],
            },
          ],
        },
        {
          name: "Madagascar",
          regions: [
            {
              name: "Central Madagascar",
              areas: ["Antananarivo"],
            },
          ],
        },
        {
          name: "Libya",
          regions: [
            {
              name: "Tripolitania",
              areas: ["Tripoli"],
            },
            {
              name: "Cyrenaica",
              areas: ["Benghazi"],
            },
          ],
        },
        {
          name: "Mali",
          regions: [
            {
              name: "Southern Mali",
              areas: ["Bamako"],
            },
          ],
        },
        {
          name: "Burkina Faso",
          regions: [
            {
              name: "Central Burkina Faso",
              areas: ["Ouagadougou"],
            },
          ],
        },
        {
          name: "Cameroon",
          regions: [
            {
              name: "Coastal Cameroon",
              areas: ["Douala"],
            },
            {
              name: "Central Cameroon",
              areas: ["Yaoundé"],
            },
          ],
        },
        {
          name: "Chad",
          regions: [
            {
              name: "Southern Chad",
              areas: ["N'Djamena"],
            },
          ],
        },
        {
          name: "Niger",
          regions: [
            {
              name: "Southwest Niger",
              areas: ["Niamey"],
            },
          ],
        },
        {
          name: "Somalia",
          regions: [
            {
              name: "Southern Somalia",
              areas: ["Mogadishu"],
            },
          ],
        },
        {
          name: "Guinea",
          regions: [
            {
              name: "Lower Guinea",
              areas: ["Conakry"],
            },
          ],
        },
        {
          name: "Gabon",
          regions: [
            {
              name: "Estuaire Province",
              areas: ["Libreville"],
            },
          ],
        },
        {
          name: "Burundi",
          regions: [
            {
              name: "Bujumbura Province",
              areas: ["Bujumbura"],
            },
          ],
        },
        {
          name: "Malawi",
          regions: [
            {
              name: "Central Malawi",
              areas: ["Lilongwe"],
            },
          ],
        },
      ],
    },
    {
      name: "Australia",
      countries: [
        {
          name: "Australia",
          regions: [
            {
              name: "New South Wales",
              areas: ["Sydney", "Newcastle", "Wollongong", "Byron Bay"],
            },
            {
              name: "Victoria",
              areas: ["Melbourne", "Geelong", "Ballarat", "Bendigo"],
            },
            {
              name: "Queensland",
              areas: ["Brisbane", "Gold Coast", "Cairns", "Townsville"],
            },
            {
              name: "South Australia",
              areas: ["Adelaide", "Mount Gambier", "Whyalla", "Port Augusta"],
            },
            {
              name: "Western Australia",
              areas: ["Perth", "Bunbury", "Mandurah", "Albany"],
            },
            {
              name: "Tasmania",
              areas: ["Hobart", "Launceston", "Devonport", "Burnie"],
            },
            {
              name: "Northern Territory",
              areas: ["Darwin", "Alice Springs", "Katherine", "Kakadu"],
            },
          ],
        },
      ],
    },
    {
      name: "Asia",
      countries: [
        {
          name: "China",
          regions: [
            {
              name: "Eastern China",
              areas: ["Shanghai", "Hangzhou", "Nanjing", "Hefei", "Jinan"],
            },
            {
              name: "Southern China",
              areas: ["Guangzhou", "Shenzhen", "Fuzhou", "Xiamen"],
            },
            {
              name: "Northern China",
              areas: ["Beijing", "Tianjin", "Shijiazhuang", "Taiyuan"],
            },
            {
              name: "Western China",
              areas: ["Chengdu", "Xi'an", "Kunming", "Lhasa", "Urumqi"],
            },
          ],
        },
        {
          name: "Japan",
          regions: [
            {
              name: "Kanto",
              areas: ["Tokyo", "Yokohama", "Kawasaki", "Saitama"],
            },
            {
              name: "Kansai",
              areas: ["Osaka", "Kyoto", "Kobe", "Nara"],
            },
            {
              name: "Chubu",
              areas: ["Nagoya", "Niigata", "Kanazawa"],
            },
            {
              name: "Hokkaido",
              areas: ["Sapporo", "Hakodate"],
            },
            {
              name: "Kyushu",
              areas: ["Fukuoka", "Nagasaki", "Kumamoto"],
            },
          ],
        },
        {
          name: "India",
          regions: [
            {
              name: "North India",
              areas: ["Delhi", "Jaipur", "Lucknow", "Chandigarh"],
            },
            {
              name: "South India",
              areas: ["Bangalore", "Chennai", "Hyderabad", "Kochi"],
            },
            {
              name: "West India",
              areas: ["Mumbai", "Pune", "Ahmedabad", "Surat"],
            },
            {
              name: "East India",
              areas: ["Kolkata", "Bhubaneswar", "Guwahati"],
            },
          ],
        },
        {
          name: "South Korea",
          regions: [
            {
              name: "Seoul Capital Area",
              areas: ["Seoul", "Incheon", "Suwon"],
            },
            {
              name: "Gyeongsang",
              areas: ["Busan", "Daegu", "Ulsan"],
            },
            {
              name: "Jeolla",
              areas: ["Gwangju", "Jeonju"],
            },
          ],
        },
        {
          name: "Indonesia",
          regions: [
            {
              name: "Java",
              areas: ["Jakarta", "Surabaya", "Bandung", "Semarang"],
            },
            {
              name: "Bali",
              areas: ["Denpasar"],
            },
            {
              name: "Sumatra",
              areas: ["Medan", "Palembang", "Pekanbaru"],
            },
          ],
        },
        {
          name: "Thailand",
          regions: [
            {
              name: "Central Thailand",
              areas: ["Bangkok", "Ayutthaya"],
            },
            {
              name: "Northern Thailand",
              areas: ["Chiang Mai", "Chiang Rai"],
            },
            {
              name: "Southern Thailand",
              areas: ["Phuket", "Hat Yai", "Krabi"],
            },
          ],
        },
        {
          name: "Philippines",
          regions: [
            {
              name: "Luzon",
              areas: ["Manila", "Quezon City", "Baguio"],
            },
            {
              name: "Visayas",
              areas: ["Cebu City", "Iloilo", "Tacloban"],
            },
            {
              name: "Mindanao",
              areas: ["Davao City", "Cagayan de Oro"],
            },
          ],
        },
        {
          name: "Vietnam",
          regions: [
            {
              name: "Northern Vietnam",
              areas: ["Hanoi", "Haiphong"],
            },
            {
              name: "Central Vietnam",
              areas: ["Da Nang", "Hue"],
            },
            {
              name: "Southern Vietnam",
              areas: ["Ho Chi Minh City", "Can Tho"],
            },
          ],
        },
        {
          name: "Malaysia",
          regions: [
            {
              name: "Peninsular Malaysia",
              areas: ["Kuala Lumpur", "Penang", "Johor Bahru", "Malacca"],
            },
            {
              name: "East Malaysia",
              areas: ["Kota Kinabalu", "Kuching"],
            },
          ],
        },
        {
          name: "Singapore",
          regions: [
            {
              name: "Singapore",
              areas: ["Singapore"],
            },
          ],
        },
        {
          name: "Pakistan",
          regions: [
            {
              name: "Punjab",
              areas: ["Lahore", "Faisalabad", "Rawalpindi"],
            },
            {
              name: "Sindh",
              areas: ["Karachi", "Hyderabad"],
            },
            {
              name: "Khyber Pakhtunkhwa",
              areas: ["Peshawar", "Abbottabad"],
            },
          ],
        },
        {
          name: "Bangladesh",
          regions: [
            {
              name: "Dhaka Division",
              areas: ["Dhaka", "Narayanganj"],
            },
            {
              name: "Chittagong Division",
              areas: ["Chittagong", "Cox’s Bazar"],
            },
          ],
        },
        {
          name: "Russia",
          regions: [
            {
              name: "Central Russia",
              areas: ["Moscow", "Tver", "Yaroslavl"],
            },
            {
              name: "Northwestern Russia",
              areas: ["Saint Petersburg", "Murmansk", "Arkhangelsk"],
            },
            {
              name: "Volga Region",
              areas: ["Nizhny Novgorod", "Kazan", "Samara", "Volgograd"],
            },
            {
              name: "Ural Region",
              areas: ["Yekaterinburg", "Chelyabinsk", "Perm"],
            },
            {
              name: "Siberia",
              areas: ["Novosibirsk", "Krasnoyarsk", "Irkutsk", "Omsk"],
            },
            {
              name: "Far East",
              areas: ["Vladivostok", "Khabarovsk", "Yakutsk"],
            },
          ],
        },
        {
          name: "Saudi Arabia",
          regions: [
            {
              name: "Riyadh Region",
              areas: ["Riyadh"],
            },
            {
              name: "Hejaz",
              areas: ["Jeddah", "Mecca", "Medina"],
            },
          ],
        },
        {
          name: "Turkey",
          regions: [
            {
              name: "Marmara",
              areas: ["Istanbul", "Bursa"],
            },
            {
              name: "Central Anatolia",
              areas: ["Ankara", "Konya"],
            },
            {
              name: "Aegean",
              areas: ["Izmir"],
            },
          ],
        },
        {
          name: "United Arab Emirates",
          regions: [
            {
              name: "Dubai",
              areas: ["Dubai"],
            },
            {
              name: "Abu Dhabi",
              areas: ["Abu Dhabi"],
            },
            {
              name: "Sharjah",
              areas: ["Sharjah"],
            },
          ],
        },
        {
          name: "Iran",
          regions: [
            {
              name: "Tehran Province",
              areas: ["Tehran"],
            },
            {
              name: "Isfahan Province",
              areas: ["Isfahan"],
            },
            {
              name: "Fars Province",
              areas: ["Shiraz"],
            },
          ],
        },
        {
          name: "Israel",
          regions: [
            {
              name: "Tel Aviv District",
              areas: ["Tel Aviv"],
            },
            {
              name: "Jerusalem District",
              areas: ["Jerusalem"],
            },
          ],
        },
        {
          name: "Iraq",
          regions: [
            {
              name: "Baghdad Governorate",
              areas: ["Baghdad"],
            },
            {
              name: "Kurdistan Region",
              areas: ["Erbil"],
            },
          ],
        },
        {
          name: "Lebanon",
          regions: [
            {
              name: "Mount Lebanon",
              areas: ["Beirut"],
            },
          ],
        },
        {
          name: "Kazakhstan",
          regions: [
            {
              name: "Almaty Region",
              areas: ["Almaty"],
            },
            {
              name: "Nur-Sultan Region",
              areas: ["Nur-Sultan"],
            },
          ],
        },
      ],
    },
    {
      name: "Europe",
      countries: [
        {
          name: "United Kingdom",
          regions: [
            {
              name: "England",
              areas: [
                "London",
                "Manchester",
                "Birmingham",
                "Liverpool",
                "Leeds",
                "Sheffield",
                "Bristol",
                "Newcastle",
                "Nottingham",
              ],
            },
            {
              name: "Scotland",
              areas: ["Edinburgh", "Glasgow", "Aberdeen", "Dundee"],
            },
            {
              name: "Wales",
              areas: ["Cardiff", "Swansea", "Newport"],
            },
            {
              name: "Northern Ireland",
              areas: ["Belfast", "Derry", "Lisburn"],
            },
          ],
        },
        {
          name: "Germany",
          regions: [
            {
              name: "Baden-Württemberg",
              areas: ["Stuttgart", "Mannheim", "Karlsruhe", "Freiburg", "Heidelberg"],
            },
            {
              name: "Bavaria",
              areas: ["Munich", "Nuremberg", "Augsburg", "Regensburg", "Würzburg"],
            },
            {
              name: "Berlin",
              areas: ["Berlin"],
            },
            {
              name: "Brandenburg",
              areas: ["Potsdam", "Cottbus", "Brandenburg an der Havel"],
            },
            {
              name: "Bremen",
              areas: ["Bremen"],
            },
            {
              name: "Hamburg",
              areas: ["Hamburg"],
            },
            {
              name: "Hesse",
              areas: ["Frankfurt", "Wiesbaden", "Darmstadt", "Kassel"],
            },
            {
              name: "Lower Saxony",
              areas: ["Hanover", "Braunschweig", "Oldenburg", "Wolfsburg", "Osnabrück"],
            },
            {
              name: "Mecklenburg-Vorpommern",
              areas: ["Rostock", "Schwerin", "Stralsund"],
            },
            {
              name: "North Rhine-Westphalia",
              areas: ["Cologne", "Düsseldorf", "Dortmund", "Essen", "Bonn", "Duisburg"],
            },
            {
              name: "Rhineland-Palatinate",
              areas: ["Mainz", "Ludwigshafen", "Koblenz", "Trier"],
            },
            {
              name: "Saarland",
              areas: ["Saarbrücken"],
            },
            {
              name: "Saxony",
              areas: ["Dresden", "Leipzig", "Chemnitz"],
            },
            {
              name: "Saxony-Anhalt",
              areas: ["Magdeburg", "Halle", "Dessau"],
            },
            {
              name: "Schleswig-Holstein",
              areas: ["Kiel", "Lübeck", "Flensburg"],
            },
            {
              name: "Thuringia",
              areas: ["Erfurt", "Jena", "Gera"],
            },
          ],
        },
        {
          name: "France",
          regions: [
            {
              name: "Île-de-France",
              areas: ["Paris", "Versailles", "Boulogne-Billancourt"],
            },
            {
              name: "Provence-Alpes-Côte d'Azur",
              areas: ["Marseille", "Nice", "Toulon", "Cannes"],
            },
            {
              name: "Auvergne-Rhône-Alpes",
              areas: ["Lyon", "Grenoble", "Saint-Étienne", "Clermont-Ferrand"],
            },
            {
              name: "Nouvelle-Aquitaine",
              areas: ["Bordeaux", "Limoges", "Poitiers"],
            },
            {
              name: "Occitanie",
              areas: ["Toulouse", "Montpellier", "Nîmes"],
            },
            {
              name: "Grand Est",
              areas: ["Strasbourg", "Reims", "Metz"],
            },
            {
              name: "Bourgogne-Franche-Comté",
              areas: ["Dijon", "Besançon", "Belfort"],
            },
            {
              name: "Normandie",
              areas: ["Rouen", "Caen", "Le Havre"],
            },
            {
              name: "Bretagne",
              areas: ["Rennes", "Brest", "Quimper"],
            },
            {
              name: "Pays de la Loire",
              areas: ["Nantes", "Angers", "Le Mans"],
            },
            {
              name: "Centre-Val de Loire",
              areas: ["Orléans", "Tours", "Bourges"],
            },
          ],
        },
        {
          name: "Italy",
          regions: [
            {
              name: "Lombardy",
              areas: ["Milan", "Bergamo", "Brescia", "Como"],
            },
            {
              name: "Lazio",
              areas: ["Rome", "Latina", "Frosinone"],
            },
            {
              name: "Veneto",
              areas: ["Venice", "Verona", "Padua", "Treviso"],
            },
            {
              name: "Campania",
              areas: ["Naples", "Salerno", "Caserta"],
            },
            {
              name: "Sicily",
              areas: ["Palermo", "Catania", "Messina"],
            },
            {
              name: "Piedmont",
              areas: ["Turin", "Novara", "Asti"],
            },
            {
              name: "Tuscany",
              areas: ["Florence", "Pisa", "Siena"],
            },
            {
              name: "Emilia-Romagna",
              areas: ["Bologna", "Modena", "Parma"],
            },
          ],
        },
        {
          name: "Spain",
          regions: [
            {
              name: "Catalonia",
              areas: ["Barcelona", "Girona", "Tarragona", "Lleida"],
            },
            {
              name: "Madrid Community",
              areas: ["Madrid", "Alcalá de Henares", "Móstoles"],
            },
            {
              name: "Andalusia",
              areas: ["Seville", "Malaga", "Granada", "Cordoba"],
            },
            {
              name: "Valencia",
              areas: ["Valencia", "Alicante", "Castellón"],
            },
            {
              name: "Galicia",
              areas: ["A Coruña", "Vigo", "Ourense"],
            },
            {
              name: "Basque Country",
              areas: ["Bilbao", "San Sebastián", "Vitoria-Gasteiz"],
            },
          ],
        },
        {
          name: "Netherlands",
          regions: [
            {
              name: "North Holland",
              areas: ["Amsterdam", "Haarlem", "Alkmaar"],
            },
            {
              name: "South Holland",
              areas: ["The Hague", "Rotterdam", "Leiden"],
            },
            {
              name: "Utrecht",
              areas: ["Utrecht", "Amersfoort"],
            },
            {
              name: "North Brabant",
              areas: ["Eindhoven", "Tilburg", "Breda"],
            },
            {
              name: "Groningen",
              areas: ["Groningen"],
            },
            {
              name: "Overijssel",
              areas: ["Zwolle", "Enschede"],
            },
          ],
        },
        {
          name: "Belgium",
          regions: [
            {
              name: "Flanders",
              areas: ["Antwerp", "Ghent", "Bruges", "Leuven"],
            },
            {
              name: "Wallonia",
              areas: ["Liège", "Namur", "Charleroi"],
            },
            {
              name: "Brussels-Capital",
              areas: ["Brussels"],
            },
          ],
        },
        {
          name: "Switzerland",
          regions: [
            {
              name: "Zurich",
              areas: ["Zurich"],
            },
            {
              name: "Geneva",
              areas: ["Geneva"],
            },
            {
              name: "Basel",
              areas: ["Basel"],
            },
            {
              name: "Bern",
              areas: ["Bern"],
            },
            {
              name: "Vaud",
              areas: ["Lausanne"],
            },
          ],
        },
        {
          name: "Austria",
          regions: [
            {
              name: "Vienna",
              areas: ["Vienna"],
            },
            {
              name: "Upper Austria",
              areas: ["Linz"],
            },
            {
              name: "Lower Austria",
              areas: ["St. Pölten"],
            },
            {
              name: "Tyrol",
              areas: ["Innsbruck", "Kufstein"],
            },
            {
              name: "Salzburg",
              areas: ["Salzburg"],
            },
          ],
        },
        {
          name: "Sweden",
          regions: [
            {
              name: "Stockholm",
              areas: ["Stockholm"],
            },
            {
              name: "Scania",
              areas: ["Malmö", "Lund"],
            },
            {
              name: "Gothenburg",
              areas: ["Gothenburg"],
            },
            {
              name: "Uppsala",
              areas: ["Uppsala"],
            },
          ],
        },
        {
          name: "Norway",
          regions: [
            {
              name: "Oslo",
              areas: ["Oslo"],
            },
            {
              name: "Bergen",
              areas: ["Bergen"],
            },
            {
              name: "Trondheim",
              areas: ["Trondheim"],
            },
          ],
        },
        {
          name: "Denmark",
          regions: [
            {
              name: "Capital Region",
              areas: ["Copenhagen", "Helsingør"],
            },
            {
              name: "Central Denmark",
              areas: ["Aarhus", "Silkeborg"],
            },
            {
              name: "North Denmark",
              areas: ["Aalborg", "Frederikshavn"],
            },
            {
              name: "South Denmark",
              areas: ["Odense", "Esbjerg"],
            },
          ],
        },
        {
          name: "Finland",
          regions: [
            {
              name: "Uusimaa",
              areas: ["Helsinki", "Espoo", "Vantaa"],
            },
            {
              name: "Pirkanmaa",
              areas: ["Tampere"],
            },
            {
              name: "Southwest Finland",
              areas: ["Turku"],
            },
            {
              name: "Lapland",
              areas: ["Rovaniemi"],
            },
          ],
        },
        {
          name: "Poland",
          regions: [
            {
              name: "Masovian",
              areas: ["Warsaw", "Radom"],
            },
            {
              name: "Silesian",
              areas: ["Katowice", "Gliwice"],
            },
            {
              name: "Lesser Poland",
              areas: ["Krakow", "Tarnów"],
            },
            {
              name: "Greater Poland",
              areas: ["Poznan", "Kalisz"],
            },
            {
              name: "Pomeranian",
              areas: ["Gdansk", "Gdynia"],
            },
          ],
        },
        {
          name: "Czech Republic",
          regions: [
            {
              name: "Prague",
              areas: ["Prague"],
            },
            {
              name: "South Moravian",
              areas: ["Brno"],
            },
            {
              name: "Moravian-Silesian",
              areas: ["Ostrava"],
            },
            {
              name: "Central Bohemian",
              areas: ["Prague (wider area)"],
            },
          ],
        },
        {
          name: "Portugal",
          regions: [
            {
              name: "Lisbon",
              areas: ["Lisbon", "Sintra"],
            },
            {
              name: "Porto",
              areas: ["Porto"],
            },
            {
              name: "Algarve",
              areas: ["Faro"],
            },
            {
              name: "Centro",
              areas: ["Coimbra"],
            },
          ],
        },
        {
          name: "Ireland",
          regions: [
            {
              name: "Leinster",
              areas: ["Dublin", "Wexford"],
            },
            {
              name: "Munster",
              areas: ["Cork", "Limerick"],
            },
            {
              name: "Connacht",
              areas: ["Galway"],
            },
            {
              name: "Ulster",
              areas: ["Belfast"],
            },
          ],
        },
        {
          name: "Hungary",
          regions: [
            {
              name: "Central Hungary",
              areas: ["Budapest"],
            },
            {
              name: "Western Transdanubia",
              areas: ["Győr", "Sopron"],
            },
            {
              name: "Northern Great Plain",
              areas: ["Debrecen", "Nyíregyháza"],
            },
          ],
        },
      ],
    },
    {
      name: "North America",
      countries: [
        {
          name: "United States",
          regions: [
            {
              name: "California",
              areas: ["Los Angeles", "San Francisco", "San Diego", "Sacramento", "San Jose"],
            },
            {
              name: "Texas",
              areas: ["Houston", "Dallas", "Austin", "San Antonio"],
            },
            {
              name: "New York",
              areas: ["New York City", "Buffalo", "Rochester", "Albany"],
            },
            {
              name: "Florida",
              areas: ["Miami", "Orlando", "Tampa", "Jacksonville"],
            },
            {
              name: "Illinois",
              areas: ["Chicago", "Aurora", "Naperville", "Rockford"],
            },
            {
              name: "Pennsylvania",
              areas: ["Philadelphia", "Pittsburgh", "Allentown"],
            },
            {
              name: "Ohio",
              areas: ["Columbus", "Cleveland", "Cincinnati", "Toledo"],
            },
            {
              name: "Georgia",
              areas: ["Atlanta", "Savannah", "Augusta"],
            },
            {
              name: "North Carolina",
              areas: ["Charlotte", "Raleigh", "Greensboro"],
            },
            {
              name: "Michigan",
              areas: ["Detroit", "Grand Rapids", "Warren"],
            },
          ],
        },
        {
          name: "Canada",
          regions: [
            {
              name: "Ontario",
              areas: ["Toronto", "Ottawa", "Mississauga", "Hamilton"],
            },
            {
              name: "Quebec",
              areas: ["Montreal", "Quebec City", "Laval"],
            },
            {
              name: "British Columbia",
              areas: ["Vancouver", "Victoria", "Surrey"],
            },
            {
              name: "Alberta",
              areas: ["Calgary", "Edmonton", "Red Deer"],
            },
            {
              name: "Manitoba",
              areas: ["Winnipeg", "Brandon"],
            },
            {
              name: "Nova Scotia",
              areas: ["Halifax"],
            },
            {
              name: "Saskatchewan",
              areas: ["Saskatoon", "Regina"],
            },
            {
              name: "New Brunswick",
              areas: ["Moncton", "Saint John"],
            },
          ],
        },
        {
          name: "Mexico",
          regions: [
            {
              name: "Mexico City",
              areas: ["Mexico City"],
            },
            {
              name: "Jalisco",
              areas: ["Guadalajara"],
            },
            {
              name: "Nuevo León",
              areas: ["Monterrey"],
            },
            {
              name: "Puebla",
              areas: ["Puebla"],
            },
            {
              name: "Guanajuato",
              areas: ["León"],
            },
          ],
        },
      ],
    },
    {
      name: "South America",
      countries: [
        {
          name: "Brazil",
          regions: [
            {
              name: "São Paulo",
              areas: ["São Paulo", "Campinas", "Santos"],
            },
            {
              name: "Rio de Janeiro",
              areas: ["Rio de Janeiro", "Niterói"],
            },
            {
              name: "Minas Gerais",
              areas: ["Belo Horizonte", "Uberlândia", "Contagem"],
            },
            {
              name: "Bahia",
              areas: ["Salvador"],
            },
          ],
        },
        {
          name: "Argentina",
          regions: [
            {
              name: "Buenos Aires",
              areas: ["Buenos Aires", "La Plata", "Mar del Plata"],
            },
            {
              name: "Córdoba",
              areas: ["Córdoba", "Villa Carlos Paz"],
            },
            {
              name: "Santa Fe",
              areas: ["Rosario", "Santa Fe"],
            },
          ],
        },
        {
          name: "Colombia",
          regions: [
            {
              name: "Bogotá",
              areas: ["Bogotá"],
            },
            {
              name: "Antioquia",
              areas: ["Medellín", "Envigado"],
            },
            {
              name: "Valle del Cauca",
              areas: ["Cali", "Palmira"],
            },
          ],
        },
        {
          name: "Chile",
          regions: [
            {
              name: "Santiago Metropolitan",
              areas: ["Santiago", "Maipú", "Puente Alto"],
            },
            {
              name: "Valparaíso",
              areas: ["Valparaíso", "Viña del Mar"],
            },
          ],
        },
        {
          name: "Peru",
          regions: [
            {
              name: "Lima",
              areas: ["Lima", "Callao"],
            },
            {
              name: "Arequipa",
              areas: ["Arequipa"],
            },
          ],
        },
        {
          name: "Ecuador",
          regions: [
            {
              name: "Pichincha",
              areas: ["Quito", "Cayambe"],
            },
            {
              name: "Guayas",
              areas: ["Guayaquil"],
            },
          ],
        },
        {
          name: "Venezuela",
          regions: [
            {
              name: "Capital District",
              areas: ["Caracas"],
            },
            {
              name: "Zulia",
              areas: ["Maracaibo"],
            },
          ],
        },
        {
          name: "Uruguay",
          regions: [
            {
              name: "Montevideo",
              areas: ["Montevideo"],
            },
            {
              name: "Canelones",
              areas: ["Canelones"],
            },
          ],
        },
        {
          name: "Paraguay",
          regions: [
            {
              name: "Asunción",
              areas: ["Asunción"],
            },
            {
              name: "Central",
              areas: ["Ciudad del Este"],
            },
          ],
        },
        {
          name: "Bolivia",
          regions: [
            {
              name: "La Paz",
              areas: ["La Paz"],
            },
            {
              name: "Santa Cruz",
              areas: ["Santa Cruz de la Sierra"],
            },
          ],
        },
      ],
    },
  ],
};

export default locationData;
