import { Card, Typography } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch"; // A more welcoming icon

function WelcomeToProspect360() {
  return (
    <Card
      sx={{
        p: 5,
        textAlign: "center",
        background: "linear-gradient(135deg,rgb(213, 58, 58) 10%,rgb(255, 102, 0) 100%)",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        borderRadius: 3,
        boxShadow: 3,
      }}
    >
      {/* Welcome Icon */}
      <RocketLaunchIcon sx={{ fontSize: 60, color: "white" }} />

      {/* Title */}
      <Typography variant="h5" fontWeight={600}>
        Teleroids Prospect 360
      </Typography>

      {/* Subtitle */}
      <Typography variant="body1" sx={{ opacity: 0.9 }}>
        Discover powerful insights and grow your network efficiently.
      </Typography>
    </Card>
  );
}

export default WelcomeToProspect360;
