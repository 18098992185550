import { useContext, useEffect, useState } from "react";
import { useSoftUIController } from "context";
import { createTheme } from "@mui/material/styles";
import { Box, Divider, Grid, Icon, Stack, ThemeProvider, Typography } from "@mui/material";
import Footer from "examples/Footer";
import { CustomerUserContext } from "App";
import { makeStyles } from "@material-ui/core/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/Navbar";
import NotesContext from "AppContext";
import axios from "axios";
import Echo from "laravel-echo";
import { pusherOptions } from "common/helpers";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { useHistory } from "react-router-dom";
import TabList from "@mui/lab/TabList";
import Note from "./Note";

function Chat() {
  const history = useHistory();

  const [controller] = useSoftUIController();

  const useStyles = makeStyles(() => ({
    titles: { fontFamily: "Poppins", fontSize: "14px", fontWeight: "500", color: "#4A4A4A" },
    value: { fontFamily: "Poppins", fontSize: "12px", fontWeight: "400", color: "#4A4A4A" },
    value2: { fontFamily: "Poppins", fontSize: "12px", fontWeight: "400", color: "#5F5F5F" },
    skillWrapper: {
      margin: "5px",
      display: "flex",
    },
    tab: {
      width: "50%",
    },
    customTablist: {
      "& .Mui-selected": {
        color: "#ff4d00 !important",
        borderColor: "#ff4d00 !important",
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#ff4d00 !important",
      },
    },
  }));

  const classes = useStyles();

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "lightgrey",
          },
        },
      },
    },
  });
  const [customerUser, setCustomerUser] = useContext(CustomerUserContext);

  const { notes, setNotes, setTotalNewNotes } = useContext(NotesContext);

  const [refreshList, setRefreshList] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [chatFilter, setChatFilter] = useState(
    history.location.state?.from === "dashboard" ? 1 : 0
  );

  // const queryParams = new URLSearchParams(window.location.search);
  const openChat = (pAppointment, res = null) => {
    if (res) {
      res.pivot_unread_notes = 0;
    }
    if (pAppointment) {
      setAppointment(null);
      setTimeout(() => {
        setAppointment(pAppointment);
      }, 500);
    }
  };
  const handleChange = (event, newValue) => {
    setChatFilter(newValue);
  };

  const feedNotes = () => {
    axios
      .get(`/api/customers/${customerUser.customer.id}/campaign-lead/notes`, {
        params: {
          search: searchQuery,
          filter: chatFilter,
        },
      })
      .then((res) => {
        if (res) {
          setNotes(res.data);

          let total = 0;
          for (let i = 0; i < res.data.length; i += 1) {
            total += res.data[i].pivot_unread_notes;
          }
          setTotalNewNotes(total);
        }
      })
      .catch(() => {
        setNotes([]);
        setTotalNewNotes(0);
      });
  };

  useEffect(() => {
    if (chatFilter !== null) {
      feedNotes();
    }
  }, [chatFilter]);

  useEffect(() => {
    const echo = new Echo(pusherOptions);

    echo.private("user.notification").listen(".new-user-notification", () => {
      feedNotes();
    });

    return () => {
      echo.leave("user.notification");
    };
  }, [notes]);

  useEffect(() => {
    feedNotes();
    const echo = new Echo(pusherOptions);
    echo
      .join("online")
      .here((users) => {
        const usersIds = [];
        users.forEach(({ id }) => {
          usersIds.push(id);
        });
        setOnlineUsers(usersIds);
      })
      .joining((user) => {
        setOnlineUsers((oldArray) => [...oldArray, user.id]);
      })
      .leaving((user) => {
        const usersIds = onlineUsers.filter((id) => id !== user.id);
        setOnlineUsers(usersIds);
      });
    return () => {};
  }, [null, refreshList]);

  // useEffect(() => {
  //   const appointmentId = queryParams.get("appointment_id");
  //   if (appointmentId !== undefined && appointmentId && notes && notes.length > 0) {
  //     const dataV = notes.filter((r) => `${r.appointment.id}` === `${appointmentId}`)[0];
  //     if (dataV && dataV.appointment) {
  //       openChat(dataV.appointment);
  //     }
  //   }

  //   return () => {};
  // }, [queryParams.get("appointment_id"), notes]);

  function handleSearch(res) {
    const nameMatches =
      res.appointment &&
      res.appointment.campaign_lead &&
      res.appointment.lead_identificator.toLowerCase().includes(searchQuery.toLowerCase());

    return nameMatches;
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar componentName="Chat" />
      </DashboardLayout>
      <Grid
        item
        container
        sx={{ backgroundColor: "#FBFAFB", overflow: "hidden" }}
        ml={controller.miniSidenav ? "100px" : "300px"}
        md={controller.miniSidenav ? 10.8 : 9}
      >
        <ThemeProvider theme={theme}>
          <Grid item container>
            <Grid
              item
              container
              mb={2}
              lg={12}
              md={12}
              sm={12}
              xs={11}
              display={{ sm: "block", lg: "flex" }}
              sx={{
                backgroundColor: "#FFFFFF",
                padding: "20px",
                borderRadius: "5px",
              }}
            >
              <Grid
                sx={{ height: "80vh", overflow: "auto", paddingRight: "2%" }}
                item
                lg={4.5}
                md={5}
                xs={12}
                maxWidth={{ sm: "100%" }}
              >
                <Stack
                  width="35%"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    paddingTop: "2%",
                    paddingBottom: "2%",
                    paddingLeft: "2%",
                    width: "100%",
                    border: isFocused ? "1px solid #a1a1aa" : "1px solid #e4e4e7",
                    "&:hover": {
                      border: "1px solid #a1a1aa",
                    },
                  }}
                  mb={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Icon sx={{ color: "#71717a" }}>search</Icon>
                  <input
                    style={{
                      border: "0",
                      background: "transparent",
                      outline: "none",
                    }}
                    type="text"
                    value={searchQuery}
                    placeholder="Search..."
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                </Stack>
                <div>
                  <Box style={{ marginBottom: "10px" }}>
                    <TabContext value={chatFilter}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleChange} className={classes.customTablist}>
                          <Tab label="All" className={classes.tab} value={0} />
                          <Tab label="UnRead" className={classes.tab} value={1} />
                        </TabList>
                      </Box>
                    </TabContext>
                  </Box>
                </div>

                {notes &&
                  notes.length > 0 &&
                  notes
                    .filter((res) => handleSearch(res))
                    .map((res) => (
                      <Grid
                        item
                        sx={{
                          backgroundColor:
                            res.appointment.class_name === "error" ? "#FBFAFB" : "#FFFFFFF",
                          padding: "30px 20px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          border: `1px solid ${
                            res.appointment.class_name === "error" ? "#FBFAFB" : "#FF4D0080"
                          }`,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          "&:hover": {
                            border: "1px solid #ff4d00",
                          },
                        }}
                        mb={2}
                        lg={12}
                        onClick={() => openChat(res.appointment, res)}
                        key={res.appointment.id}
                      >
                        <Stack>
                          <Typography
                            fontFamily="Poppins"
                            fontSize="15px"
                            fontWeight={res.pivot_unread_notes !== 0 ? "700" : "500"}
                            color={res.appointment.class_name === "error" ? "#C3BDBD" : "#FF4D00D9"}
                          >
                            {res.appointment &&
                              res.appointment.campaign_lead &&
                              res.appointment.lead_identificator.replace("Firmenname: ", "")}
                          </Typography>
                          <Typography
                            fontFamily="Poppins"
                            fontSize="12px"
                            fontWeight="500"
                            color={res.appointment.class_name === "error" ? "#C3BDBD" : "#4A4A4A"}
                          >
                            {res.pivot_updated_at}
                          </Typography>
                        </Stack>
                        {res.pivot_unread_notes !== 0 ? (
                          <Typography
                            sx={{
                              backgroundColor: "#FF4D00D9",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            mr={2}
                            fontSize="12px"
                            fontFamily="Poppins"
                            fontWeight="500"
                            color="#FFFFFF"
                          >
                            {res.pivot_unread_notes}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    ))}
              </Grid>
              <Divider
                sx={{
                  display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
                  height: { sm: "0", md: "0", lg: "100%" },
                }}
                orientation="vertical"
              />
              <Grid item ml={2} lg={7} md={7} xs={12} maxWidth={{ sm: "100%" }}>
                {appointment && appointment.customer_write_permission ? (
                  <Note
                    customerUser={customerUser}
                    setCustomerUser={setCustomerUser}
                    appointment={appointment}
                    refreshList={refreshList}
                    setRefreshList={setRefreshList}
                    onlineUsers={onlineUsers}
                  />
                ) : (
                  <Box>
                    {appointment && !appointment.customer_write_permission && (
                      <>
                        <Box textAlign="center" p={2} pt={10}>
                          <Icon color="error" fontSize="large">
                            error_outline
                          </Icon>
                          <br />
                          You dont have permission to access the info
                        </Box>
                      </>
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
        <Stack sx={{ width: "100%", flexShrink: "0" }}>
          <Footer />
        </Stack>
      </Grid>
    </>
  );
}

export default Chat;
